export const getPromptCatalog2LevelElementsSubCategories = /* GraphQL */ `
    query GetPromptCatalog($id: ID!) {
        getPromptCatalog(id: $id) {
            id
            colors {
                name
                values
                subCategories {
                    name
                    values
                }
            }
            elements {
                name
                subCategories {
                    name
                    values
                    subCategories {
                        name
                        values
                    }
                }
            }
            descriptions {
                name
                values
                subCategories {
                    name
                    values
                }
            }
            createdAt
            updatedAt
        }
    }
`;

export const listPacksWithDescendantsData = /* GraphQL */ `
    query ListPacks($filter: ModelPackFilterInput, $limit: Int, $nextToken: String) {
        listPacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                description
                level
                name
                owner
                status
                thumbnailPath
                updatedAt
                createdAt
                tags
                order
                exampleImages
                bundledScenes {
                    items {
                        bundledScenes {
                            id
                            name
                            description
                            status
                            thumbnailPath
                            updatedAt
                            createdAt
                            mediaType
                            scenes {
                                items {
                                    scene {
                                        id
                                        hasBlurryBG
                                        format
                                        includeInPromptCatalog
                                        isAnimatable
                                        name
                                        optionsPerBlock
                                        owner
                                        path
                                        productSupportedSize
                                        sceneDescription
                                        selectedValuesOfBlocks
                                        status
                                        themes {
                                            main
                                            sub
                                        }
                                        brief {
                                            blocks
                                            description
                                        }
                                        thumbnailPath
                                        type
                                        viewsSupported
                                        updatedAt
                                        createdAt
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const generatedPackByUsernameWithDescendantsData = /* GraphQL */ `
    query GeneratedPackByUsername(
        $username: String!
        $sortDirection: ModelSortDirection
        $filter: ModelGeneratedPackFilterInput
        $limit: Int
        $nextToken: String
    ) {
        generatedPackByUsername(username: $username, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                status
                username
                createdAt
                updatedAt
                generatedPackPackId
                generatedPackProductId
                owner
                product {
                    id
                    path
                    name
                    status
                    productType
                    thumbnailPath
                    supportedViewType
                    industryType
                    productFamily
                    size {
                        width
                        height
                        depth
                    }
                    assets {
                        url
                    }
                    dataForInvoice {
                        customProductComplexityRank
                        priceId
                        immersiveProductId
                        priceValue
                        priceCurrency
                    }
                    username
                    createdAt
                    updatedAt
                    owner
                }
                pack {
                    id
                    description
                    level
                    name
                    owner
                    status
                    thumbnailPath
                    updatedAt
                    createdAt
                    bundledScenes {
                        items {
                            bundledScenes {
                                id
                                name
                                description
                                status
                                thumbnailPath
                                updatedAt
                                createdAt
                                mediaType
                                scenes {
                                    items {
                                        scene {
                                            id
                                            hasBlurryBG
                                            format
                                            includeInPromptCatalog
                                            isAnimatable
                                            name
                                            optionsPerBlock
                                            owner
                                            path
                                            productSupportedSize
                                            sceneDescription
                                            selectedValuesOfBlocks
                                            status
                                            themes {
                                                main
                                                sub
                                            }
                                            brief {
                                                blocks
                                                description
                                            }
                                            thumbnailPath
                                            type
                                            viewsSupported
                                            updatedAt
                                            createdAt
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
