/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      path
      name
      status
      productType
      thumbnailPath
      supportedViewType
      industryType
      productFamily
      size {
        width
        height
        depth
      }
      assets {
        url
      }
      dataForInvoice {
        customProductComplexityRank
        priceId
        immersiveProductId
        priceValue
        priceCurrency
      }
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      path
      name
      status
      productType
      thumbnailPath
      supportedViewType
      industryType
      productFamily
      size {
        width
        height
        depth
      }
      assets {
        url
      }
      dataForInvoice {
        customProductComplexityRank
        priceId
        immersiveProductId
        priceValue
        priceCurrency
      }
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      path
      name
      status
      productType
      thumbnailPath
      supportedViewType
      industryType
      productFamily
      size {
        width
        height
        depth
      }
      assets {
        url
      }
      dataForInvoice {
        customProductComplexityRank
        priceId
        immersiveProductId
        priceValue
        priceCurrency
      }
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProductSample = /* GraphQL */ `
  mutation CreateProductSample(
    $input: CreateProductSampleInput!
    $condition: ModelProductSampleConditionInput
  ) {
    createProductSample(input: $input, condition: $condition) {
      id
      path
      name
      thumbnailPath
      size {
        width
        height
        depth
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProductSample = /* GraphQL */ `
  mutation UpdateProductSample(
    $input: UpdateProductSampleInput!
    $condition: ModelProductSampleConditionInput
  ) {
    updateProductSample(input: $input, condition: $condition) {
      id
      path
      name
      thumbnailPath
      size {
        width
        height
        depth
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProductSample = /* GraphQL */ `
  mutation DeleteProductSample(
    $input: DeleteProductSampleInput!
    $condition: ModelProductSampleConditionInput
  ) {
    deleteProductSample(input: $input, condition: $condition) {
      id
      path
      name
      thumbnailPath
      size {
        width
        height
        depth
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createScene = /* GraphQL */ `
  mutation CreateScene(
    $input: CreateSceneInput!
    $condition: ModelSceneConditionInput
  ) {
    createScene(input: $input, condition: $condition) {
      id
      path
      name
      brief {
        description
        blocks
      }
      status
      type
      format
      sceneDescription
      includeInPromptCatalog
      viewsSupported
      thumbnailPath
      isAnimatable
      selectedValuesOfBlocks
      optionsPerBlock
      themes {
        main
        sub
      }
      productSupportedSize
      hasBlurryBG
      bundledScenes {
        items {
          id
          sceneId
          bundledScenesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateScene = /* GraphQL */ `
  mutation UpdateScene(
    $input: UpdateSceneInput!
    $condition: ModelSceneConditionInput
  ) {
    updateScene(input: $input, condition: $condition) {
      id
      path
      name
      brief {
        description
        blocks
      }
      status
      type
      format
      sceneDescription
      includeInPromptCatalog
      viewsSupported
      thumbnailPath
      isAnimatable
      selectedValuesOfBlocks
      optionsPerBlock
      themes {
        main
        sub
      }
      productSupportedSize
      hasBlurryBG
      bundledScenes {
        items {
          id
          sceneId
          bundledScenesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteScene = /* GraphQL */ `
  mutation DeleteScene(
    $input: DeleteSceneInput!
    $condition: ModelSceneConditionInput
  ) {
    deleteScene(input: $input, condition: $condition) {
      id
      path
      name
      brief {
        description
        blocks
      }
      status
      type
      format
      sceneDescription
      includeInPromptCatalog
      viewsSupported
      thumbnailPath
      isAnimatable
      selectedValuesOfBlocks
      optionsPerBlock
      themes {
        main
        sub
      }
      productSupportedSize
      hasBlurryBG
      bundledScenes {
        items {
          id
          sceneId
          bundledScenesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createBundledScenes = /* GraphQL */ `
  mutation CreateBundledScenes(
    $input: CreateBundledScenesInput!
    $condition: ModelBundledScenesConditionInput
  ) {
    createBundledScenes(input: $input, condition: $condition) {
      id
      name
      description
      thumbnailPath
      scenes {
        items {
          id
          sceneId
          bundledScenesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      packs {
        items {
          id
          bundledScenesId
          packId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      mediaType
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBundledScenes = /* GraphQL */ `
  mutation UpdateBundledScenes(
    $input: UpdateBundledScenesInput!
    $condition: ModelBundledScenesConditionInput
  ) {
    updateBundledScenes(input: $input, condition: $condition) {
      id
      name
      description
      thumbnailPath
      scenes {
        items {
          id
          sceneId
          bundledScenesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      packs {
        items {
          id
          bundledScenesId
          packId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      mediaType
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBundledScenes = /* GraphQL */ `
  mutation DeleteBundledScenes(
    $input: DeleteBundledScenesInput!
    $condition: ModelBundledScenesConditionInput
  ) {
    deleteBundledScenes(input: $input, condition: $condition) {
      id
      name
      description
      thumbnailPath
      scenes {
        items {
          id
          sceneId
          bundledScenesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      packs {
        items {
          id
          bundledScenesId
          packId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      mediaType
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPack = /* GraphQL */ `
  mutation CreatePack(
    $input: CreatePackInput!
    $condition: ModelPackConditionInput
  ) {
    createPack(input: $input, condition: $condition) {
      id
      name
      description
      thumbnailPath
      level
      bundledScenes {
        items {
          id
          bundledScenesId
          packId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      tags
      status
      order
      exampleImages
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePack = /* GraphQL */ `
  mutation UpdatePack(
    $input: UpdatePackInput!
    $condition: ModelPackConditionInput
  ) {
    updatePack(input: $input, condition: $condition) {
      id
      name
      description
      thumbnailPath
      level
      bundledScenes {
        items {
          id
          bundledScenesId
          packId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      tags
      status
      order
      exampleImages
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePack = /* GraphQL */ `
  mutation DeletePack(
    $input: DeletePackInput!
    $condition: ModelPackConditionInput
  ) {
    deletePack(input: $input, condition: $condition) {
      id
      name
      description
      thumbnailPath
      level
      bundledScenes {
        items {
          id
          bundledScenesId
          packId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      tags
      status
      order
      exampleImages
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGeneratedPack = /* GraphQL */ `
  mutation CreateGeneratedPack(
    $input: CreateGeneratedPackInput!
    $condition: ModelGeneratedPackConditionInput
  ) {
    createGeneratedPack(input: $input, condition: $condition) {
      id
      pack {
        id
        name
        description
        thumbnailPath
        level
        bundledScenes {
          nextToken
        }
        tags
        status
        order
        exampleImages
        createdAt
        updatedAt
        owner
      }
      product {
        id
        path
        name
        status
        productType
        thumbnailPath
        supportedViewType
        industryType
        productFamily
        size {
          width
          height
          depth
        }
        assets {
          url
        }
        dataForInvoice {
          customProductComplexityRank
          priceId
          immersiveProductId
          priceValue
          priceCurrency
        }
        username
        createdAt
        updatedAt
        owner
      }
      status
      username
      createdAt
      updatedAt
      generatedPackPackId
      generatedPackProductId
      owner
    }
  }
`;
export const updateGeneratedPack = /* GraphQL */ `
  mutation UpdateGeneratedPack(
    $input: UpdateGeneratedPackInput!
    $condition: ModelGeneratedPackConditionInput
  ) {
    updateGeneratedPack(input: $input, condition: $condition) {
      id
      pack {
        id
        name
        description
        thumbnailPath
        level
        bundledScenes {
          nextToken
        }
        tags
        status
        order
        exampleImages
        createdAt
        updatedAt
        owner
      }
      product {
        id
        path
        name
        status
        productType
        thumbnailPath
        supportedViewType
        industryType
        productFamily
        size {
          width
          height
          depth
        }
        assets {
          url
        }
        dataForInvoice {
          customProductComplexityRank
          priceId
          immersiveProductId
          priceValue
          priceCurrency
        }
        username
        createdAt
        updatedAt
        owner
      }
      status
      username
      createdAt
      updatedAt
      generatedPackPackId
      generatedPackProductId
      owner
    }
  }
`;
export const deleteGeneratedPack = /* GraphQL */ `
  mutation DeleteGeneratedPack(
    $input: DeleteGeneratedPackInput!
    $condition: ModelGeneratedPackConditionInput
  ) {
    deleteGeneratedPack(input: $input, condition: $condition) {
      id
      pack {
        id
        name
        description
        thumbnailPath
        level
        bundledScenes {
          nextToken
        }
        tags
        status
        order
        exampleImages
        createdAt
        updatedAt
        owner
      }
      product {
        id
        path
        name
        status
        productType
        thumbnailPath
        supportedViewType
        industryType
        productFamily
        size {
          width
          height
          depth
        }
        assets {
          url
        }
        dataForInvoice {
          customProductComplexityRank
          priceId
          immersiveProductId
          priceValue
          priceCurrency
        }
        username
        createdAt
        updatedAt
        owner
      }
      status
      username
      createdAt
      updatedAt
      generatedPackPackId
      generatedPackProductId
      owner
    }
  }
`;
export const createTrackedProgressItem = /* GraphQL */ `
  mutation CreateTrackedProgressItem(
    $input: CreateTrackedProgressItemInput!
    $condition: ModelTrackedProgressItemConditionInput
  ) {
    createTrackedProgressItem(input: $input, condition: $condition) {
      id
      progress
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTrackedProgressItem = /* GraphQL */ `
  mutation UpdateTrackedProgressItem(
    $input: UpdateTrackedProgressItemInput!
    $condition: ModelTrackedProgressItemConditionInput
  ) {
    updateTrackedProgressItem(input: $input, condition: $condition) {
      id
      progress
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTrackedProgressItem = /* GraphQL */ `
  mutation DeleteTrackedProgressItem(
    $input: DeleteTrackedProgressItemInput!
    $condition: ModelTrackedProgressItemConditionInput
  ) {
    deleteTrackedProgressItem(input: $input, condition: $condition) {
      id
      progress
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createStory = /* GraphQL */ `
  mutation CreateStory(
    $input: CreateStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    createStory(input: $input, condition: $condition) {
      id
      sceneId
      scenePath
      sceneName
      sceneBrief {
        description
        blocks
      }
      sceneStatus
      sceneType
      format
      sceneHasBlurryBG
      selectedValuesOfBlocks
      optionsPerBlock
      status
      similarity
      isGeneratedFromHistory
      reimaginesData {
        s3key
        type
        selectedValuesOfBlocks
        reimagineInfo {
          option
          category
          preset
        }
      }
      owner
      createdAt
      updatedAt
      projectStoriesId
    }
  }
`;
export const updateStory = /* GraphQL */ `
  mutation UpdateStory(
    $input: UpdateStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    updateStory(input: $input, condition: $condition) {
      id
      sceneId
      scenePath
      sceneName
      sceneBrief {
        description
        blocks
      }
      sceneStatus
      sceneType
      format
      sceneHasBlurryBG
      selectedValuesOfBlocks
      optionsPerBlock
      status
      similarity
      isGeneratedFromHistory
      reimaginesData {
        s3key
        type
        selectedValuesOfBlocks
        reimagineInfo {
          option
          category
          preset
        }
      }
      owner
      createdAt
      updatedAt
      projectStoriesId
    }
  }
`;
export const deleteStory = /* GraphQL */ `
  mutation DeleteStory(
    $input: DeleteStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    deleteStory(input: $input, condition: $condition) {
      id
      sceneId
      scenePath
      sceneName
      sceneBrief {
        description
        blocks
      }
      sceneStatus
      sceneType
      format
      sceneHasBlurryBG
      selectedValuesOfBlocks
      optionsPerBlock
      status
      similarity
      isGeneratedFromHistory
      reimaginesData {
        s3key
        type
        selectedValuesOfBlocks
        reimagineInfo {
          option
          category
          preset
        }
      }
      owner
      createdAt
      updatedAt
      projectStoriesId
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      userBrief {
        description
        blocks
      }
      selectedValuesOfUserBriefBlocks
      stories {
        items {
          id
          sceneId
          scenePath
          sceneName
          sceneStatus
          sceneType
          format
          sceneHasBlurryBG
          selectedValuesOfBlocks
          optionsPerBlock
          status
          similarity
          isGeneratedFromHistory
          owner
          createdAt
          updatedAt
          projectStoriesId
        }
        nextToken
      }
      containingFolder {
        id
        name
        projects {
          nextToken
        }
        childFolders {
          nextToken
        }
        parent {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        owner
        createdAt
        updatedAt
        folderChildFoldersId
        folderParentId
      }
      type
      updatedAt
      status
      wizardDialogData {
        step_1
        step_2
      }
      imagesContstructingThumbnailIds
      username
      createdAt
      folderProjectsId
      owner
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      userBrief {
        description
        blocks
      }
      selectedValuesOfUserBriefBlocks
      stories {
        items {
          id
          sceneId
          scenePath
          sceneName
          sceneStatus
          sceneType
          format
          sceneHasBlurryBG
          selectedValuesOfBlocks
          optionsPerBlock
          status
          similarity
          isGeneratedFromHistory
          owner
          createdAt
          updatedAt
          projectStoriesId
        }
        nextToken
      }
      containingFolder {
        id
        name
        projects {
          nextToken
        }
        childFolders {
          nextToken
        }
        parent {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        owner
        createdAt
        updatedAt
        folderChildFoldersId
        folderParentId
      }
      type
      updatedAt
      status
      wizardDialogData {
        step_1
        step_2
      }
      imagesContstructingThumbnailIds
      username
      createdAt
      folderProjectsId
      owner
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      userBrief {
        description
        blocks
      }
      selectedValuesOfUserBriefBlocks
      stories {
        items {
          id
          sceneId
          scenePath
          sceneName
          sceneStatus
          sceneType
          format
          sceneHasBlurryBG
          selectedValuesOfBlocks
          optionsPerBlock
          status
          similarity
          isGeneratedFromHistory
          owner
          createdAt
          updatedAt
          projectStoriesId
        }
        nextToken
      }
      containingFolder {
        id
        name
        projects {
          nextToken
        }
        childFolders {
          nextToken
        }
        parent {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        owner
        createdAt
        updatedAt
        folderChildFoldersId
        folderParentId
      }
      type
      updatedAt
      status
      wizardDialogData {
        step_1
        step_2
      }
      imagesContstructingThumbnailIds
      username
      createdAt
      folderProjectsId
      owner
    }
  }
`;
export const createFolder = /* GraphQL */ `
  mutation CreateFolder(
    $input: CreateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    createFolder(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          name
          selectedValuesOfUserBriefBlocks
          type
          updatedAt
          status
          imagesContstructingThumbnailIds
          username
          createdAt
          folderProjectsId
          owner
        }
        nextToken
      }
      childFolders {
        items {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        nextToken
      }
      parent {
        id
        name
        projects {
          nextToken
        }
        childFolders {
          nextToken
        }
        parent {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        owner
        createdAt
        updatedAt
        folderChildFoldersId
        folderParentId
      }
      owner
      createdAt
      updatedAt
      folderChildFoldersId
      folderParentId
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder(
    $input: UpdateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    updateFolder(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          name
          selectedValuesOfUserBriefBlocks
          type
          updatedAt
          status
          imagesContstructingThumbnailIds
          username
          createdAt
          folderProjectsId
          owner
        }
        nextToken
      }
      childFolders {
        items {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        nextToken
      }
      parent {
        id
        name
        projects {
          nextToken
        }
        childFolders {
          nextToken
        }
        parent {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        owner
        createdAt
        updatedAt
        folderChildFoldersId
        folderParentId
      }
      owner
      createdAt
      updatedAt
      folderChildFoldersId
      folderParentId
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder(
    $input: DeleteFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    deleteFolder(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          name
          selectedValuesOfUserBriefBlocks
          type
          updatedAt
          status
          imagesContstructingThumbnailIds
          username
          createdAt
          folderProjectsId
          owner
        }
        nextToken
      }
      childFolders {
        items {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        nextToken
      }
      parent {
        id
        name
        projects {
          nextToken
        }
        childFolders {
          nextToken
        }
        parent {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        owner
        createdAt
        updatedAt
        folderChildFoldersId
        folderParentId
      }
      owner
      createdAt
      updatedAt
      folderChildFoldersId
      folderParentId
    }
  }
`;
export const createSpace = /* GraphQL */ `
  mutation CreateSpace(
    $input: CreateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    createSpace(input: $input, condition: $condition) {
      id
      name
      rootFolder {
        id
        name
        projects {
          nextToken
        }
        childFolders {
          nextToken
        }
        parent {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        owner
        createdAt
        updatedAt
        folderChildFoldersId
        folderParentId
      }
      username
      createdAt
      updatedAt
      spaceRootFolderId
      owner
    }
  }
`;
export const updateSpace = /* GraphQL */ `
  mutation UpdateSpace(
    $input: UpdateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    updateSpace(input: $input, condition: $condition) {
      id
      name
      rootFolder {
        id
        name
        projects {
          nextToken
        }
        childFolders {
          nextToken
        }
        parent {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        owner
        createdAt
        updatedAt
        folderChildFoldersId
        folderParentId
      }
      username
      createdAt
      updatedAt
      spaceRootFolderId
      owner
    }
  }
`;
export const deleteSpace = /* GraphQL */ `
  mutation DeleteSpace(
    $input: DeleteSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    deleteSpace(input: $input, condition: $condition) {
      id
      name
      rootFolder {
        id
        name
        projects {
          nextToken
        }
        childFolders {
          nextToken
        }
        parent {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        owner
        createdAt
        updatedAt
        folderChildFoldersId
        folderParentId
      }
      username
      createdAt
      updatedAt
      spaceRootFolderId
      owner
    }
  }
`;
export const createActiveSpace = /* GraphQL */ `
  mutation CreateActiveSpace(
    $input: CreateActiveSpaceInput!
    $condition: ModelActiveSpaceConditionInput
  ) {
    createActiveSpace(input: $input, condition: $condition) {
      space {
        id
        name
        rootFolder {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        username
        createdAt
        updatedAt
        spaceRootFolderId
        owner
      }
      owner
      createdAt
      updatedAt
      activeSpaceSpaceId
    }
  }
`;
export const updateActiveSpace = /* GraphQL */ `
  mutation UpdateActiveSpace(
    $input: UpdateActiveSpaceInput!
    $condition: ModelActiveSpaceConditionInput
  ) {
    updateActiveSpace(input: $input, condition: $condition) {
      space {
        id
        name
        rootFolder {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        username
        createdAt
        updatedAt
        spaceRootFolderId
        owner
      }
      owner
      createdAt
      updatedAt
      activeSpaceSpaceId
    }
  }
`;
export const deleteActiveSpace = /* GraphQL */ `
  mutation DeleteActiveSpace(
    $input: DeleteActiveSpaceInput!
    $condition: ModelActiveSpaceConditionInput
  ) {
    deleteActiveSpace(input: $input, condition: $condition) {
      space {
        id
        name
        rootFolder {
          id
          name
          owner
          createdAt
          updatedAt
          folderChildFoldersId
          folderParentId
        }
        username
        createdAt
        updatedAt
        spaceRootFolderId
        owner
      }
      owner
      createdAt
      updatedAt
      activeSpaceSpaceId
    }
  }
`;
export const createActiveProduct = /* GraphQL */ `
  mutation CreateActiveProduct(
    $input: CreateActiveProductInput!
    $condition: ModelActiveProductConditionInput
  ) {
    createActiveProduct(input: $input, condition: $condition) {
      product {
        id
        path
        name
        status
        productType
        thumbnailPath
        supportedViewType
        industryType
        productFamily
        size {
          width
          height
          depth
        }
        assets {
          url
        }
        dataForInvoice {
          customProductComplexityRank
          priceId
          immersiveProductId
          priceValue
          priceCurrency
        }
        username
        createdAt
        updatedAt
        owner
      }
      owner
      createdAt
      updatedAt
      activeProductProductId
    }
  }
`;
export const updateActiveProduct = /* GraphQL */ `
  mutation UpdateActiveProduct(
    $input: UpdateActiveProductInput!
    $condition: ModelActiveProductConditionInput
  ) {
    updateActiveProduct(input: $input, condition: $condition) {
      product {
        id
        path
        name
        status
        productType
        thumbnailPath
        supportedViewType
        industryType
        productFamily
        size {
          width
          height
          depth
        }
        assets {
          url
        }
        dataForInvoice {
          customProductComplexityRank
          priceId
          immersiveProductId
          priceValue
          priceCurrency
        }
        username
        createdAt
        updatedAt
        owner
      }
      owner
      createdAt
      updatedAt
      activeProductProductId
    }
  }
`;
export const deleteActiveProduct = /* GraphQL */ `
  mutation DeleteActiveProduct(
    $input: DeleteActiveProductInput!
    $condition: ModelActiveProductConditionInput
  ) {
    deleteActiveProduct(input: $input, condition: $condition) {
      product {
        id
        path
        name
        status
        productType
        thumbnailPath
        supportedViewType
        industryType
        productFamily
        size {
          width
          height
          depth
        }
        assets {
          url
        }
        dataForInvoice {
          customProductComplexityRank
          priceId
          immersiveProductId
          priceValue
          priceCurrency
        }
        username
        createdAt
        updatedAt
        owner
      }
      owner
      createdAt
      updatedAt
      activeProductProductId
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      onboarding {
        formSteps {
          id
          data
          isSubmited
        }
        uiSteps {
          id
          completedStepTypes
          isSubmited
        }
      }
      roles {
        id
        value
      }
      goals {
        id
        value
      }
      stripeId
      limitation {
        generatedStoriesInLimitPeriod
        createdProjectsInLimitPeriod
        createdCustomProductsInLimitPeriod
        createdStandardProductsInLimitPeriod
        generatedReimagines
        downloadedImages
        downloadedVideos
        generatedPacks
        subscriptionStartDate
        limitResetDate
        projectLimit
        storiesLimit
        storiesLimitPeriod
        customProducts
        standardProducts
        images
        videos
        packsLimit
      }
      seenSceneIds
      isSubscriptionCanceled
      isReimagineClicked
      trialSubscriptionStatus
      location {
        country
        countryName
        countryCode
        countryCodeISO3
        currency
        currencyName
        languages
      }
      paymentMethodStatus
      seenGeneratedPackIds
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      onboarding {
        formSteps {
          id
          data
          isSubmited
        }
        uiSteps {
          id
          completedStepTypes
          isSubmited
        }
      }
      roles {
        id
        value
      }
      goals {
        id
        value
      }
      stripeId
      limitation {
        generatedStoriesInLimitPeriod
        createdProjectsInLimitPeriod
        createdCustomProductsInLimitPeriod
        createdStandardProductsInLimitPeriod
        generatedReimagines
        downloadedImages
        downloadedVideos
        generatedPacks
        subscriptionStartDate
        limitResetDate
        projectLimit
        storiesLimit
        storiesLimitPeriod
        customProducts
        standardProducts
        images
        videos
        packsLimit
      }
      seenSceneIds
      isSubscriptionCanceled
      isReimagineClicked
      trialSubscriptionStatus
      location {
        country
        countryName
        countryCode
        countryCodeISO3
        currency
        currencyName
        languages
      }
      paymentMethodStatus
      seenGeneratedPackIds
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      onboarding {
        formSteps {
          id
          data
          isSubmited
        }
        uiSteps {
          id
          completedStepTypes
          isSubmited
        }
      }
      roles {
        id
        value
      }
      goals {
        id
        value
      }
      stripeId
      limitation {
        generatedStoriesInLimitPeriod
        createdProjectsInLimitPeriod
        createdCustomProductsInLimitPeriod
        createdStandardProductsInLimitPeriod
        generatedReimagines
        downloadedImages
        downloadedVideos
        generatedPacks
        subscriptionStartDate
        limitResetDate
        projectLimit
        storiesLimit
        storiesLimitPeriod
        customProducts
        standardProducts
        images
        videos
        packsLimit
      }
      seenSceneIds
      isSubscriptionCanceled
      isReimagineClicked
      trialSubscriptionStatus
      location {
        country
        countryName
        countryCode
        countryCodeISO3
        currency
        currencyName
        languages
      }
      paymentMethodStatus
      seenGeneratedPackIds
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createPromptCatalog = /* GraphQL */ `
  mutation CreatePromptCatalog(
    $input: CreatePromptCatalogInput!
    $condition: ModelPromptCatalogConditionInput
  ) {
    createPromptCatalog(input: $input, condition: $condition) {
      id
      colors {
        name
        values
        subCategories {
          name
          values
        }
      }
      elements {
        name
        values
        subCategories {
          name
          values
        }
      }
      descriptions {
        name
        values
        subCategories {
          name
          values
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePromptCatalog = /* GraphQL */ `
  mutation UpdatePromptCatalog(
    $input: UpdatePromptCatalogInput!
    $condition: ModelPromptCatalogConditionInput
  ) {
    updatePromptCatalog(input: $input, condition: $condition) {
      id
      colors {
        name
        values
        subCategories {
          name
          values
        }
      }
      elements {
        name
        values
        subCategories {
          name
          values
        }
      }
      descriptions {
        name
        values
        subCategories {
          name
          values
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePromptCatalog = /* GraphQL */ `
  mutation DeletePromptCatalog(
    $input: DeletePromptCatalogInput!
    $condition: ModelPromptCatalogConditionInput
  ) {
    deletePromptCatalog(input: $input, condition: $condition) {
      id
      colors {
        name
        values
        subCategories {
          name
          values
        }
      }
      elements {
        name
        values
        subCategories {
          name
          values
        }
      }
      descriptions {
        name
        values
        subCategories {
          name
          values
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPromptHistory = /* GraphQL */ `
  mutation CreatePromptHistory(
    $input: CreatePromptHistoryInput!
    $condition: ModelPromptHistoryConditionInput
  ) {
    createPromptHistory(input: $input, condition: $condition) {
      description
      score
      username
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePromptHistory = /* GraphQL */ `
  mutation UpdatePromptHistory(
    $input: UpdatePromptHistoryInput!
    $condition: ModelPromptHistoryConditionInput
  ) {
    updatePromptHistory(input: $input, condition: $condition) {
      description
      score
      username
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePromptHistory = /* GraphQL */ `
  mutation DeletePromptHistory(
    $input: DeletePromptHistoryInput!
    $condition: ModelPromptHistoryConditionInput
  ) {
    deletePromptHistory(input: $input, condition: $condition) {
      description
      score
      username
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      name
      poorAvailabilityDetectedTime
      createdAt
      updatedAt
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      name
      poorAvailabilityDetectedTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      name
      poorAvailabilityDetectedTime
      createdAt
      updatedAt
    }
  }
`;
export const createSceneBundledScenes = /* GraphQL */ `
  mutation CreateSceneBundledScenes(
    $input: CreateSceneBundledScenesInput!
    $condition: ModelSceneBundledScenesConditionInput
  ) {
    createSceneBundledScenes(input: $input, condition: $condition) {
      id
      sceneId
      bundledScenesId
      scene {
        id
        path
        name
        brief {
          description
          blocks
        }
        status
        type
        format
        sceneDescription
        includeInPromptCatalog
        viewsSupported
        thumbnailPath
        isAnimatable
        selectedValuesOfBlocks
        optionsPerBlock
        themes {
          main
          sub
        }
        productSupportedSize
        hasBlurryBG
        bundledScenes {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      bundledScenes {
        id
        name
        description
        thumbnailPath
        scenes {
          nextToken
        }
        packs {
          nextToken
        }
        mediaType
        status
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSceneBundledScenes = /* GraphQL */ `
  mutation UpdateSceneBundledScenes(
    $input: UpdateSceneBundledScenesInput!
    $condition: ModelSceneBundledScenesConditionInput
  ) {
    updateSceneBundledScenes(input: $input, condition: $condition) {
      id
      sceneId
      bundledScenesId
      scene {
        id
        path
        name
        brief {
          description
          blocks
        }
        status
        type
        format
        sceneDescription
        includeInPromptCatalog
        viewsSupported
        thumbnailPath
        isAnimatable
        selectedValuesOfBlocks
        optionsPerBlock
        themes {
          main
          sub
        }
        productSupportedSize
        hasBlurryBG
        bundledScenes {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      bundledScenes {
        id
        name
        description
        thumbnailPath
        scenes {
          nextToken
        }
        packs {
          nextToken
        }
        mediaType
        status
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSceneBundledScenes = /* GraphQL */ `
  mutation DeleteSceneBundledScenes(
    $input: DeleteSceneBundledScenesInput!
    $condition: ModelSceneBundledScenesConditionInput
  ) {
    deleteSceneBundledScenes(input: $input, condition: $condition) {
      id
      sceneId
      bundledScenesId
      scene {
        id
        path
        name
        brief {
          description
          blocks
        }
        status
        type
        format
        sceneDescription
        includeInPromptCatalog
        viewsSupported
        thumbnailPath
        isAnimatable
        selectedValuesOfBlocks
        optionsPerBlock
        themes {
          main
          sub
        }
        productSupportedSize
        hasBlurryBG
        bundledScenes {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      bundledScenes {
        id
        name
        description
        thumbnailPath
        scenes {
          nextToken
        }
        packs {
          nextToken
        }
        mediaType
        status
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPackBundledScenes = /* GraphQL */ `
  mutation CreatePackBundledScenes(
    $input: CreatePackBundledScenesInput!
    $condition: ModelPackBundledScenesConditionInput
  ) {
    createPackBundledScenes(input: $input, condition: $condition) {
      id
      bundledScenesId
      packId
      bundledScenes {
        id
        name
        description
        thumbnailPath
        scenes {
          nextToken
        }
        packs {
          nextToken
        }
        mediaType
        status
        createdAt
        updatedAt
        owner
      }
      pack {
        id
        name
        description
        thumbnailPath
        level
        bundledScenes {
          nextToken
        }
        tags
        status
        order
        exampleImages
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePackBundledScenes = /* GraphQL */ `
  mutation UpdatePackBundledScenes(
    $input: UpdatePackBundledScenesInput!
    $condition: ModelPackBundledScenesConditionInput
  ) {
    updatePackBundledScenes(input: $input, condition: $condition) {
      id
      bundledScenesId
      packId
      bundledScenes {
        id
        name
        description
        thumbnailPath
        scenes {
          nextToken
        }
        packs {
          nextToken
        }
        mediaType
        status
        createdAt
        updatedAt
        owner
      }
      pack {
        id
        name
        description
        thumbnailPath
        level
        bundledScenes {
          nextToken
        }
        tags
        status
        order
        exampleImages
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePackBundledScenes = /* GraphQL */ `
  mutation DeletePackBundledScenes(
    $input: DeletePackBundledScenesInput!
    $condition: ModelPackBundledScenesConditionInput
  ) {
    deletePackBundledScenes(input: $input, condition: $condition) {
      id
      bundledScenesId
      packId
      bundledScenes {
        id
        name
        description
        thumbnailPath
        scenes {
          nextToken
        }
        packs {
          nextToken
        }
        mediaType
        status
        createdAt
        updatedAt
        owner
      }
      pack {
        id
        name
        description
        thumbnailPath
        level
        bundledScenes {
          nextToken
        }
        tags
        status
        order
        exampleImages
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
