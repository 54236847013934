import { Product, ProductStatus } from '../../src/API';
import { DataOperations } from '../../src/operations/DataOperations';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import { HubCallback, HubCapsule } from '@aws-amplify/core/lib-esm/Hub';
import AmplifyUtils from '../../src/utils/AmplifyUtils';

AmplifyUtils.configureAmplify();

export default class ProductDataHandler {
    //constants
    private static readonly DEFAULT_PRODCUT: Product = {
        __typename: 'Product',
        id: 'default_product',
        path: 'https://kartivpub.s3-us-west-1.amazonaws.com/Product%20Models/Default%20Models/Beverage%20Can/kartiv_default_can.glb',
        name: 'The Kartiv Can',
        status: ProductStatus.AVAILABLE,
        username: '',
        createdAt: '2021-08-04T18:00:00.000Z',
        updatedAt: '2021-08-04T18:00:00.000Z',
    };

    private dataOperations: DataOperations = new DataOperations();
    private productDownloadedPromise: Promise<void> | undefined = undefined;
    private activeProductDownloadedPromise: Promise<void> | undefined = undefined;
    private products: Product[] = [];

    private activeProduct: Product = ProductDataHandler.DEFAULT_PRODCUT;

    private user: CognitoUser | undefined = undefined;

    handleAuthEvent: HubCallback = (event: HubCapsule) => {
        const { payload } = event;
        const previousUserName = this.user?.getUsername() ?? '';
        switch (payload.event) {
            case 'signIn':
                this.user = payload.data;
                break;
            case 'signOut':
                this.user = undefined;
                break;
        }

        if (previousUserName != this.user?.getUsername()) {
            this.initProducts();
        }
    };

    constructor() {
        this.initProducts();

        Hub.listen('auth', this.handleAuthEvent);
        Auth.currentAuthenticatedUser()
            .then((user) => {
                if (this.user == undefined) {
                    this.user = user;
                    this.initProducts();
                }
            })
            .catch((e) => console.log(e));
    }

    private initProducts() {
        this.productDownloadedPromise = undefined;
        this.activeProductDownloadedPromise = undefined;
        this.products = [];
        this.activeProduct = ProductDataHandler.DEFAULT_PRODCUT;

        if (this.user == undefined) {
            return;
        }
        this.productDownloadedPromise = new Promise((resolve) => {
            if (this.user == undefined) {
                return;
            }
            this.dataOperations.getDisplayableUserProducts(this.user).then((products) => {
                this.products = products;
                resolve();

                this.activeProductDownloadedPromise = new Promise((resolve) => {
                    if (this.user == undefined) {
                        return;
                    }
                    this.dataOperations.getActiveUserProduct(this.user, this.products).then((product) => {
                        this.activeProduct = product == undefined ? ProductDataHandler.DEFAULT_PRODCUT : product;

                        resolve();
                    });
                });
            });
        });
    }

    private async waitForProductsDownload(): Promise<void> {
        return new Promise(async (resolve) => {
            while (this.productDownloadedPromise === undefined) {
                await new Promise((r) => setTimeout(r, 100));
            }
            await this.productDownloadedPromise;
            resolve();
        });
    }

    private async waitForActiveProductDownload(): Promise<void> {
        return new Promise(async (resolve) => {
            while (this.activeProductDownloadedPromise === undefined) {
                await new Promise((r) => setTimeout(r, 100));
            }
            await this.activeProductDownloadedPromise;
            resolve();
        });
    }

    public async getProducts(initProductsState?: boolean): Promise<Product[]> {
        if (initProductsState) {
            this.initProducts();
        }
        await this.waitForProductsDownload();

        return this.products;
    }

    public async getProductsCount(): Promise<number> {
        await this.waitForProductsDownload();

        return this.products.length;
    }

    public getProductById(productId: string): Product | undefined {
        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].id == productId) {
                return this.products[i];
            }
        }
        return undefined;
    }

    public async getActiveProduct(): Promise<Product> {
        await this.waitForActiveProductDownload();

        console.log('getActiveProduct', this.activeProduct);
        return this.activeProduct;
    }

    public async setActiveProduct(productId: string) {
        console.log('product ' + productId + ' active ' + this.activeProduct.id);
        if (this.activeProduct.id == productId || this.user == undefined) {
            console.log(
                'no activation needed: ',
                this.user == undefined ? 'user is undefined' : 'active product is the same as the requested product',
            );

            return;
        }
        await this.waitForProductsDownload();
        //loop on products to find the product with the id
        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].id == productId) {
                this.activeProduct = this.products[i];
                await this.dataOperations.activateUserProduct(this.user, productId);
                console.log('product ' + this.activeProduct.name + ' activated ');
                break;
            }
        }
    }
}
