import { Amplify } from 'aws-amplify';
import awsConfig from '../aws-exports';
import jwtDecode from 'jwt-decode';
import { LambdaName } from './types';

export default class AmplifyUtils {
    static configureAmplify() {
        const isLocalhost = process.env.NEXT_PUBLIC_HOST === 'localhost';
        const branchName = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;

        const branchNameWithoutSlash = branchName?.replace(/\//g, '-') ?? '';

        const redirectSignIns = awsConfig.oauth.redirectSignIn.split(',');
        const localRedirectSignIn = 'http://localhost:3000/';

        const branchNameIndexInRedirectSignIns = redirectSignIns.findIndex((redirectSignIn) =>
            redirectSignIn.includes(branchNameWithoutSlash),
        );
        const productionRedirectSignIn =
            branchNameIndexInRedirectSignIns > -1 ? redirectSignIns[branchNameIndexInRedirectSignIns] : 'https://app.kartiv.com/';

        const redirectSignOuts = awsConfig.oauth.redirectSignOut.split(',');
        const localRedirectSignOut = 'http://localhost:3000/';

        const branchNameIndexInRedirectSignOut = redirectSignOuts.findIndex((redirectSignOut) =>
            redirectSignOut.includes(branchNameWithoutSlash),
        );
        const productionRedirectSignOut =
            branchNameIndexInRedirectSignOut > -1 ? redirectSignOuts[branchNameIndexInRedirectSignOut] : 'https://app.kartiv.com/';

        const updatedAwsConfig = {
            ...awsConfig,
            oauth: {
                ...awsConfig.oauth,
                redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
                redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
            },
        };

        // console.log("updatedAwsConfig", updatedAwsConfig)

        Amplify.configure(updatedAwsConfig);
    }

    static getUserPoolId(): string {
        return awsConfig.aws_user_pools_id;
    }

    static getCognitoRegion(): string {
        return awsConfig.aws_cognito_region;
    }

    static getAwsRegion(): string {
        return awsConfig.aws_project_region;
    }

    static getAppsyncRegion(): string {
        return awsConfig.aws_appsync_region;
    }

    static getAppsyncGraphQlEndpoint(): string {
        return awsConfig.aws_appsync_graphqlEndpoint;
    }

    static getRESTApiName(): string {
        return awsConfig.aws_cloud_logic_custom[0].name;
    }

    static getRESTApiEndpoint(): string {
        return awsConfig.aws_cloud_logic_custom[0].endpoint;
    }

    static getAmplifyEnv(): string {
        const env = awsConfig.aws_user_files_s3_bucket.slice(awsConfig.aws_user_files_s3_bucket.indexOf('-') + 1);
        return env;
    }

    static getUserFilesBucketName(): string {
        return awsConfig.aws_user_files_s3_bucket;
    }

    static getLambdaUrl(stage: string, lambdaName: string): string {
        let lambdaUrl = '';
        switch (lambdaName) {
            case LambdaName.PRE_PROCESSED_SCENE_DATA:
                lambdaUrl =
                    stage === 'production'
                        ? 'https://b4cp27c7hfscm5lhapxacirimy0mmwfh.lambda-url.us-east-1.on.aws/'
                        : 'https://gcpg3n7ymvli6f6cvnkpbh2isa0lsuee.lambda-url.us-east-1.on.aws/';
                break;
            default:
                lambdaUrl = '';
        }

        return lambdaUrl;
    }

    static getRemoveBackgroundPath(): string {
        return '/kartivappRemoveImageBackground';
    }

    static isUserInGroup(idToken: string, groupName: string): boolean {
        try {
            const decodedToken: { [key: string]: any } = jwtDecode(idToken);
            const userGroups = decodedToken['cognito:groups'] || [];

            return userGroups.includes(groupName);
        } catch (error) {
            console.error('Error decoding JWT:', error);
            return false;
        }
    }
}
