/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProduct = /* GraphQL */ `
    query GetProduct($id: ID!) {
        getProduct(id: $id) {
            id
            path
            name
            status
            productType
            thumbnailPath
            supportedViewType
            industryType
            productFamily
            size {
                width
                height
                depth
            }
            assets {
                url
            }
            dataForInvoice {
                customProductComplexityRank
                priceId
                immersiveProductId
                priceValue
                priceCurrency
            }
            username
            createdAt
            updatedAt
            owner
        }
    }
`;
export const listProducts = /* GraphQL */ `
    query ListProducts($filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
        listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                path
                name
                status
                productType
                thumbnailPath
                supportedViewType
                industryType
                productFamily
                size {
                    width
                    height
                    depth
                }
                assets {
                    url
                }
                dataForInvoice {
                    customProductComplexityRank
                    priceId
                    immersiveProductId
                    priceValue
                    priceCurrency
                }
                username
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const getProductSample = /* GraphQL */ `
    query GetProductSample($id: ID!) {
        getProductSample(id: $id) {
            id
            path
            name
            thumbnailPath
            size {
                width
                height
                depth
            }
            createdAt
            updatedAt
            owner
        }
    }
`;
export const listProductSamples = /* GraphQL */ `
    query ListProductSamples($filter: ModelProductSampleFilterInput, $limit: Int, $nextToken: String) {
        listProductSamples(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                path
                name
                thumbnailPath
                size {
                    width
                    height
                    depth
                }
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const getScene = /* GraphQL */ `
    query GetScene($id: ID!) {
        getScene(id: $id) {
            id
            path
            name
            brief {
                description
                blocks
            }
            status
            type
            format
            sceneDescription
            includeInPromptCatalog
            viewsSupported
            thumbnailPath
            isAnimatable
            selectedValuesOfBlocks
            optionsPerBlock
            themes {
                main
                sub
            }
            productSupportedSize
            hasBlurryBG
            bundledScenes {
                items {
                    id
                    sceneId
                    bundledScenesId
                    createdAt
                    updatedAt
                    owner
                }
                nextToken
            }
            createdAt
            updatedAt
            owner
        }
    }
`;
export const listScenes = /* GraphQL */ `
    query ListScenes($filter: ModelSceneFilterInput, $limit: Int, $nextToken: String) {
        listScenes(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                path
                name
                brief {
                    description
                    blocks
                }
                status
                type
                format
                sceneDescription
                includeInPromptCatalog
                viewsSupported
                thumbnailPath
                isAnimatable
                selectedValuesOfBlocks
                optionsPerBlock
                themes {
                    main
                    sub
                }
                productSupportedSize
                hasBlurryBG
                bundledScenes {
                    nextToken
                }
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const getBundledScenes = /* GraphQL */ `
    query GetBundledScenes($id: ID!) {
        getBundledScenes(id: $id) {
            id
            name
            description
            thumbnailPath
            scenes {
                items {
                    id
                    sceneId
                    bundledScenesId
                    createdAt
                    updatedAt
                    owner
                }
                nextToken
            }
            packs {
                items {
                    id
                    bundledScenesId
                    packId
                    createdAt
                    updatedAt
                    owner
                }
                nextToken
            }
            mediaType
            status
            createdAt
            updatedAt
            owner
        }
    }
`;
export const listBundledScenes = /* GraphQL */ `
    query ListBundledScenes($filter: ModelBundledScenesFilterInput, $limit: Int, $nextToken: String) {
        listBundledScenes(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                description
                thumbnailPath
                scenes {
                    nextToken
                }
                packs {
                    nextToken
                }
                mediaType
                status
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const getPack = /* GraphQL */ `
    query GetPack($id: ID!) {
        getPack(id: $id) {
            id
            name
            description
            thumbnailPath
            level
            bundledScenes {
                items {
                    id
                    bundledScenesId
                    packId
                    createdAt
                    updatedAt
                    owner
                }
                nextToken
            }
            tags
            status
            order
            exampleImages
            createdAt
            updatedAt
            owner
        }
    }
`;
export const listPacks = /* GraphQL */ `
    query ListPacks($filter: ModelPackFilterInput, $limit: Int, $nextToken: String) {
        listPacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                description
                thumbnailPath
                level
                bundledScenes {
                    nextToken
                }
                tags
                status
                order
                exampleImages
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const getGeneratedPack = /* GraphQL */ `
    query GetGeneratedPack($id: ID!) {
        getGeneratedPack(id: $id) {
            id
            pack {
                id
                name
                description
                thumbnailPath
                level
                bundledScenes {
                    nextToken
                }
                tags
                status
                order
                exampleImages
                createdAt
                updatedAt
                owner
            }
            product {
                id
                path
                name
                status
                productType
                thumbnailPath
                supportedViewType
                industryType
                productFamily
                size {
                    width
                    height
                    depth
                }
                assets {
                    url
                }
                dataForInvoice {
                    customProductComplexityRank
                    priceId
                    immersiveProductId
                    priceValue
                    priceCurrency
                }
                username
                createdAt
                updatedAt
                owner
            }
            status
            username
            createdAt
            updatedAt
            generatedPackPackId
            generatedPackProductId
            owner
        }
    }
`;
export const listGeneratedPacks = /* GraphQL */ `
    query ListGeneratedPacks($filter: ModelGeneratedPackFilterInput, $limit: Int, $nextToken: String) {
        listGeneratedPacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                pack {
                    id
                    name
                    description
                    thumbnailPath
                    level
                    tags
                    status
                    order
                    exampleImages
                    createdAt
                    updatedAt
                    owner
                }
                product {
                    id
                    path
                    name
                    status
                    productType
                    thumbnailPath
                    supportedViewType
                    industryType
                    productFamily
                    username
                    createdAt
                    updatedAt
                    owner
                }
                status
                username
                createdAt
                updatedAt
                generatedPackPackId
                generatedPackProductId
                owner
            }
            nextToken
        }
    }
`;
export const getTrackedProgressItem = /* GraphQL */ `
    query GetTrackedProgressItem($id: ID!) {
        getTrackedProgressItem(id: $id) {
            id
            progress
            createdAt
            updatedAt
            owner
        }
    }
`;
export const listTrackedProgressItems = /* GraphQL */ `
    query ListTrackedProgressItems($filter: ModelTrackedProgressItemFilterInput, $limit: Int, $nextToken: String) {
        listTrackedProgressItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                progress
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const getStory = /* GraphQL */ `
    query GetStory($id: ID!) {
        getStory(id: $id) {
            id
            sceneId
            scenePath
            sceneName
            sceneBrief {
                description
                blocks
            }
            sceneStatus
            sceneType
            format
            sceneHasBlurryBG
            selectedValuesOfBlocks
            optionsPerBlock
            status
            similarity
            isGeneratedFromHistory
            reimaginesData {
                s3key
                type
                selectedValuesOfBlocks
                reimagineInfo {
                    option
                    category
                    preset
                }
            }
            owner
            createdAt
            updatedAt
            projectStoriesId
        }
    }
`;
export const listStories = /* GraphQL */ `
    query ListStories($filter: ModelStoryFilterInput, $limit: Int, $nextToken: String) {
        listStories(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                sceneId
                scenePath
                sceneName
                sceneBrief {
                    description
                    blocks
                }
                sceneStatus
                sceneType
                format
                sceneHasBlurryBG
                selectedValuesOfBlocks
                optionsPerBlock
                status
                similarity
                isGeneratedFromHistory
                reimaginesData {
                    s3key
                    type
                    selectedValuesOfBlocks
                }
                owner
                createdAt
                updatedAt
                projectStoriesId
            }
            nextToken
        }
    }
`;
export const getProject = /* GraphQL */ `
    query GetProject($id: ID!) {
        getProject(id: $id) {
            id
            name
            userBrief {
                description
                blocks
            }
            selectedValuesOfUserBriefBlocks
            stories {
                items {
                    id
                    sceneBrief {
                        blocks
                        description
                    }
                    sceneId
                    scenePath
                    sceneName
                    sceneStatus
                    sceneType
                    format
                    sceneHasBlurryBG
                    selectedValuesOfBlocks
                    optionsPerBlock
                    status
                    similarity
                    isGeneratedFromHistory
                    reimaginesData {
                        s3key
                        type
                        selectedValuesOfBlocks
                        reimagineInfo {
                            option
                            category
                            preset
                        }
                    }
                    owner
                    createdAt
                    updatedAt
                    projectStoriesId
                }
                nextToken
            }
            containingFolder {
                id
                name
                projects {
                    nextToken
                }
                childFolders {
                    nextToken
                }
                parent {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                owner
                createdAt
                updatedAt
                folderChildFoldersId
                folderParentId
            }
            type
            updatedAt
            status
            wizardDialogData {
                step_1
                step_2
            }
            imagesContstructingThumbnailIds
            username
            createdAt
            folderProjectsId
            owner
        }
    }
`;
export const listProjects = /* GraphQL */ `
    query ListProjects($filter: ModelProjectFilterInput, $limit: Int, $nextToken: String) {
        listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                userBrief {
                    description
                    blocks
                }
                selectedValuesOfUserBriefBlocks
                stories {
                    nextToken
                }
                containingFolder {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                type
                updatedAt
                status
                wizardDialogData {
                    step_1
                    step_2
                }
                imagesContstructingThumbnailIds
                username
                createdAt
                folderProjectsId
                owner
            }
            nextToken
        }
    }
`;
export const getFolder = /* GraphQL */ `
    query GetFolder($id: ID!) {
        getFolder(id: $id) {
            id
            name
            projects {
                items {
                    id
                    name
                    selectedValuesOfUserBriefBlocks
                    type
                    updatedAt
                    status
                    imagesContstructingThumbnailIds
                    username
                    createdAt
                    folderProjectsId
                    owner
                }
                nextToken
            }
            childFolders {
                items {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                nextToken
            }
            parent {
                id
                name
                projects {
                    nextToken
                }
                childFolders {
                    nextToken
                }
                parent {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                owner
                createdAt
                updatedAt
                folderChildFoldersId
                folderParentId
            }
            owner
            createdAt
            updatedAt
            folderChildFoldersId
            folderParentId
        }
    }
`;
export const listFolders = /* GraphQL */ `
    query ListFolders($filter: ModelFolderFilterInput, $limit: Int, $nextToken: String) {
        listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                projects {
                    nextToken
                }
                childFolders {
                    nextToken
                }
                parent {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                owner
                createdAt
                updatedAt
                folderChildFoldersId
                folderParentId
            }
            nextToken
        }
    }
`;
export const getSpace = /* GraphQL */ `
    query GetSpace($id: ID!) {
        getSpace(id: $id) {
            id
            name
            rootFolder {
                id
                name
                projects {
                    nextToken
                }
                childFolders {
                    nextToken
                }
                parent {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                owner
                createdAt
                updatedAt
                folderChildFoldersId
                folderParentId
            }
            username
            createdAt
            updatedAt
            spaceRootFolderId
            owner
        }
    }
`;
export const listSpaces = /* GraphQL */ `
    query ListSpaces($filter: ModelSpaceFilterInput, $limit: Int, $nextToken: String) {
        listSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                rootFolder {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                username
                createdAt
                updatedAt
                spaceRootFolderId
                owner
            }
            nextToken
        }
    }
`;
export const getActiveSpace = /* GraphQL */ `
    query GetActiveSpace($owner: String!) {
        getActiveSpace(owner: $owner) {
            space {
                id
                name
                rootFolder {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                username
                createdAt
                updatedAt
                spaceRootFolderId
                owner
            }
            owner
            createdAt
            updatedAt
            activeSpaceSpaceId
        }
    }
`;
export const listActiveSpaces = /* GraphQL */ `
    query ListActiveSpaces(
        $owner: String
        $filter: ModelActiveSpaceFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listActiveSpaces(owner: $owner, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
            items {
                space {
                    id
                    name
                    username
                    createdAt
                    updatedAt
                    spaceRootFolderId
                    owner
                }
                owner
                createdAt
                updatedAt
                activeSpaceSpaceId
            }
            nextToken
        }
    }
`;
export const getActiveProduct = /* GraphQL */ `
    query GetActiveProduct($owner: String!) {
        getActiveProduct(owner: $owner) {
            product {
                id
                path
                name
                status
                productType
                thumbnailPath
                supportedViewType
                industryType
                productFamily
                size {
                    width
                    height
                    depth
                }
                assets {
                    url
                }
                dataForInvoice {
                    customProductComplexityRank
                    priceId
                    immersiveProductId
                    priceValue
                    priceCurrency
                }
                username
                createdAt
                updatedAt
                owner
            }
            owner
            createdAt
            updatedAt
            activeProductProductId
        }
    }
`;
export const listActiveProducts = /* GraphQL */ `
    query ListActiveProducts(
        $owner: String
        $filter: ModelActiveProductFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listActiveProducts(owner: $owner, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
            items {
                product {
                    id
                    path
                    name
                    status
                    productType
                    thumbnailPath
                    supportedViewType
                    industryType
                    productFamily
                    username
                    createdAt
                    updatedAt
                    owner
                }
                owner
                createdAt
                updatedAt
                activeProductProductId
            }
            nextToken
        }
    }
`;
export const getUser = /* GraphQL */ `
    query GetUser($owner: String!) {
        getUser(owner: $owner) {
            onboarding {
                formSteps {
                    id
                    data
                    isSubmited
                }
                uiSteps {
                    id
                    completedStepTypes
                    isSubmited
                }
            }
            roles {
                id
                value
            }
            goals {
                id
                value
            }
            stripeId
            limitation {
                generatedStoriesInLimitPeriod
                createdProjectsInLimitPeriod
                createdCustomProductsInLimitPeriod
                createdStandardProductsInLimitPeriod
                generatedReimagines
                downloadedImages
                downloadedVideos
                generatedPacks
                subscriptionStartDate
                limitResetDate
                projectLimit
                storiesLimit
                storiesLimitPeriod
                customProducts
                standardProducts
                images
                videos
                packsLimit
            }
            seenSceneIds
            isSubscriptionCanceled
            isReimagineClicked
            trialSubscriptionStatus
            location {
                country
                countryName
                countryCode
                countryCodeISO3
                currency
                currencyName
                languages
            }
            paymentMethodStatus
            seenGeneratedPackIds
            owner
            createdAt
            updatedAt
        }
    }
`;
export const listUsers = /* GraphQL */ `
    query ListUsers($owner: String, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listUsers(owner: $owner, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
            items {
                roles {
                    id
                    value
                }
                goals {
                    id
                    value
                }
                stripeId
                limitation {
                    generatedStoriesInLimitPeriod
                    createdProjectsInLimitPeriod
                    createdCustomProductsInLimitPeriod
                    createdStandardProductsInLimitPeriod
                    generatedReimagines
                    downloadedImages
                    downloadedVideos
                    generatedPacks
                    subscriptionStartDate
                    limitResetDate
                    projectLimit
                    storiesLimit
                    storiesLimitPeriod
                    customProducts
                    standardProducts
                    images
                    videos
                    packsLimit
                }
                seenSceneIds
                isSubscriptionCanceled
                isReimagineClicked
                trialSubscriptionStatus
                location {
                    country
                    countryName
                    countryCode
                    countryCodeISO3
                    currency
                    currencyName
                    languages
                }
                paymentMethodStatus
                seenGeneratedPackIds
                owner
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const getPromptCatalog = /* GraphQL */ `
    query GetPromptCatalog($id: ID!) {
        getPromptCatalog(id: $id) {
            id
            colors {
                name
                values
                subCategories {
                    name
                    values
                }
            }
            elements {
                name
                values
                subCategories {
                    name
                    values
                }
            }
            descriptions {
                name
                values
                subCategories {
                    name
                    values
                }
            }
            createdAt
            updatedAt
        }
    }
`;
export const listPromptCatalogs = /* GraphQL */ `
    query ListPromptCatalogs($filter: ModelPromptCatalogFilterInput, $limit: Int, $nextToken: String) {
        listPromptCatalogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                colors {
                    name
                    values
                }
                elements {
                    name
                    values
                }
                descriptions {
                    name
                    values
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const getPromptHistory = /* GraphQL */ `
    query GetPromptHistory($id: ID!) {
        getPromptHistory(id: $id) {
            description
            score
            username
            id
            createdAt
            updatedAt
            owner
        }
    }
`;
export const listPromptHistories = /* GraphQL */ `
    query ListPromptHistories($filter: ModelPromptHistoryFilterInput, $limit: Int, $nextToken: String) {
        listPromptHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                description
                score
                username
                id
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const getService = /* GraphQL */ `
    query GetService($id: ID!) {
        getService(id: $id) {
            id
            name
            poorAvailabilityDetectedTime
            createdAt
            updatedAt
        }
    }
`;
export const listServices = /* GraphQL */ `
    query ListServices($filter: ModelServiceFilterInput, $limit: Int, $nextToken: String) {
        listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                poorAvailabilityDetectedTime
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const getSceneBundledScenes = /* GraphQL */ `
    query GetSceneBundledScenes($id: ID!) {
        getSceneBundledScenes(id: $id) {
            id
            sceneId
            bundledScenesId
            scene {
                id
                path
                name
                brief {
                    description
                    blocks
                }
                status
                type
                format
                sceneDescription
                includeInPromptCatalog
                viewsSupported
                thumbnailPath
                isAnimatable
                selectedValuesOfBlocks
                optionsPerBlock
                themes {
                    main
                    sub
                }
                productSupportedSize
                hasBlurryBG
                bundledScenes {
                    nextToken
                }
                createdAt
                updatedAt
                owner
            }
            bundledScenes {
                id
                name
                description
                thumbnailPath
                scenes {
                    nextToken
                }
                packs {
                    nextToken
                }
                mediaType
                status
                createdAt
                updatedAt
                owner
            }
            createdAt
            updatedAt
            owner
        }
    }
`;
export const listSceneBundledScenes = /* GraphQL */ `
    query ListSceneBundledScenes($filter: ModelSceneBundledScenesFilterInput, $limit: Int, $nextToken: String) {
        listSceneBundledScenes(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                sceneId
                bundledScenesId
                scene {
                    id
                    path
                    name
                    status
                    type
                    format
                    sceneDescription
                    includeInPromptCatalog
                    viewsSupported
                    thumbnailPath
                    isAnimatable
                    selectedValuesOfBlocks
                    optionsPerBlock
                    productSupportedSize
                    hasBlurryBG
                    createdAt
                    updatedAt
                    owner
                }
                bundledScenes {
                    id
                    name
                    description
                    thumbnailPath
                    mediaType
                    status
                    createdAt
                    updatedAt
                    owner
                }
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const getPackBundledScenes = /* GraphQL */ `
    query GetPackBundledScenes($id: ID!) {
        getPackBundledScenes(id: $id) {
            id
            bundledScenesId
            packId
            bundledScenes {
                id
                name
                description
                thumbnailPath
                scenes {
                    nextToken
                }
                packs {
                    nextToken
                }
                mediaType
                status
                createdAt
                updatedAt
                owner
            }
            pack {
                id
                name
                description
                thumbnailPath
                level
                bundledScenes {
                    nextToken
                }
                tags
                status
                order
                exampleImages
                createdAt
                updatedAt
                owner
            }
            createdAt
            updatedAt
            owner
        }
    }
`;
export const listPackBundledScenes = /* GraphQL */ `
    query ListPackBundledScenes($filter: ModelPackBundledScenesFilterInput, $limit: Int, $nextToken: String) {
        listPackBundledScenes(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                bundledScenesId
                packId
                bundledScenes {
                    id
                    name
                    description
                    thumbnailPath
                    mediaType
                    status
                    createdAt
                    updatedAt
                    owner
                }
                pack {
                    id
                    name
                    description
                    thumbnailPath
                    level
                    tags
                    status
                    order
                    exampleImages
                    createdAt
                    updatedAt
                    owner
                }
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const productByUsernameAndStatus = /* GraphQL */ `
    query ProductByUsernameAndStatus(
        $username: String!
        $status: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProductFilterInput
        $limit: Int
        $nextToken: String
    ) {
        productByUsernameAndStatus(
            username: $username
            status: $status
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                path
                name
                status
                productType
                thumbnailPath
                supportedViewType
                industryType
                productFamily
                size {
                    width
                    height
                    depth
                }
                assets {
                    url
                }
                dataForInvoice {
                    customProductComplexityRank
                    priceId
                    immersiveProductId
                    priceValue
                    priceCurrency
                }
                username
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const generatedPackByUsername = /* GraphQL */ `
    query GeneratedPackByUsername(
        $username: String!
        $sortDirection: ModelSortDirection
        $filter: ModelGeneratedPackFilterInput
        $limit: Int
        $nextToken: String
    ) {
        generatedPackByUsername(username: $username, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                pack {
                    id
                    name
                    description
                    thumbnailPath
                    level
                    tags
                    status
                    order
                    exampleImages
                    createdAt
                    updatedAt
                    owner
                }
                product {
                    id
                    path
                    name
                    status
                    productType
                    thumbnailPath
                    supportedViewType
                    industryType
                    productFamily
                    username
                    createdAt
                    updatedAt
                    owner
                }
                status
                username
                createdAt
                updatedAt
                generatedPackPackId
                generatedPackProductId
                owner
            }
            nextToken
        }
    }
`;
export const projectsByLastUpdated = /* GraphQL */ `
    query ProjectsByLastUpdated(
        $type: String!
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProjectFilterInput
        $limit: Int
        $nextToken: String
    ) {
        projectsByLastUpdated(
            type: $type
            updatedAt: $updatedAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                userBrief {
                    description
                    blocks
                }
                selectedValuesOfUserBriefBlocks
                stories {
                    nextToken
                }
                containingFolder {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                type
                updatedAt
                status
                wizardDialogData {
                    step_1
                    step_2
                }
                imagesContstructingThumbnailIds
                username
                createdAt
                folderProjectsId
                owner
            }
            nextToken
        }
    }
`;
export const projectByUsername = /* GraphQL */ `
    query ProjectByUsername(
        $username: String!
        $sortDirection: ModelSortDirection
        $filter: ModelProjectFilterInput
        $limit: Int
        $nextToken: String
    ) {
        projectByUsername(username: $username, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                userBrief {
                    description
                    blocks
                }
                selectedValuesOfUserBriefBlocks
                stories {
                    nextToken
                }
                containingFolder {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                type
                updatedAt
                status
                wizardDialogData {
                    step_1
                    step_2
                }
                imagesContstructingThumbnailIds
                username
                createdAt
                folderProjectsId
                owner
            }
            nextToken
        }
    }
`;
export const spaceByUsername = /* GraphQL */ `
    query SpaceByUsername(
        $username: String!
        $sortDirection: ModelSortDirection
        $filter: ModelSpaceFilterInput
        $limit: Int
        $nextToken: String
    ) {
        spaceByUsername(username: $username, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                rootFolder {
                    id
                    name
                    owner
                    createdAt
                    updatedAt
                    folderChildFoldersId
                    folderParentId
                }
                username
                createdAt
                updatedAt
                spaceRootFolderId
                owner
            }
            nextToken
        }
    }
`;
export const promptHistoryByUsername = /* GraphQL */ `
    query PromptHistoryByUsername(
        $username: String!
        $sortDirection: ModelSortDirection
        $filter: ModelPromptHistoryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        promptHistoryByUsername(username: $username, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                description
                score
                username
                id
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const sceneBundledScenesBySceneId = /* GraphQL */ `
    query SceneBundledScenesBySceneId(
        $sceneId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelSceneBundledScenesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        sceneBundledScenesBySceneId(
            sceneId: $sceneId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                sceneId
                bundledScenesId
                scene {
                    id
                    path
                    name
                    status
                    type
                    format
                    sceneDescription
                    includeInPromptCatalog
                    viewsSupported
                    thumbnailPath
                    isAnimatable
                    selectedValuesOfBlocks
                    optionsPerBlock
                    productSupportedSize
                    hasBlurryBG
                    createdAt
                    updatedAt
                    owner
                }
                bundledScenes {
                    id
                    name
                    description
                    thumbnailPath
                    mediaType
                    status
                    createdAt
                    updatedAt
                    owner
                }
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const sceneBundledScenesByBundledScenesId = /* GraphQL */ `
    query SceneBundledScenesByBundledScenesId(
        $bundledScenesId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelSceneBundledScenesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        sceneBundledScenesByBundledScenesId(
            bundledScenesId: $bundledScenesId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                sceneId
                bundledScenesId
                scene {
                    id
                    path
                    name
                    status
                    type
                    format
                    sceneDescription
                    includeInPromptCatalog
                    viewsSupported
                    thumbnailPath
                    isAnimatable
                    selectedValuesOfBlocks
                    optionsPerBlock
                    productSupportedSize
                    hasBlurryBG
                    createdAt
                    updatedAt
                    owner
                }
                bundledScenes {
                    id
                    name
                    description
                    thumbnailPath
                    mediaType
                    status
                    createdAt
                    updatedAt
                    owner
                }
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const packBundledScenesByBundledScenesId = /* GraphQL */ `
    query PackBundledScenesByBundledScenesId(
        $bundledScenesId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelPackBundledScenesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        packBundledScenesByBundledScenesId(
            bundledScenesId: $bundledScenesId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                bundledScenesId
                packId
                bundledScenes {
                    id
                    name
                    description
                    thumbnailPath
                    mediaType
                    status
                    createdAt
                    updatedAt
                    owner
                }
                pack {
                    id
                    name
                    description
                    thumbnailPath
                    level
                    tags
                    status
                    order
                    exampleImages
                    createdAt
                    updatedAt
                    owner
                }
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
export const packBundledScenesByPackId = /* GraphQL */ `
    query PackBundledScenesByPackId(
        $packId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelPackBundledScenesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        packBundledScenesByPackId(packId: $packId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                bundledScenesId
                packId
                bundledScenes {
                    id
                    name
                    description
                    thumbnailPath
                    mediaType
                    status
                    createdAt
                    updatedAt
                    owner
                }
                pack {
                    id
                    name
                    description
                    thumbnailPath
                    level
                    tags
                    status
                    order
                    exampleImages
                    createdAt
                    updatedAt
                    owner
                }
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;
