export const createGeneratedPackWithDescendantsData = /* GraphQL */ `
    mutation CreateGeneratedPack($input: CreateGeneratedPackInput!, $condition: ModelGeneratedPackConditionInput) {
        createGeneratedPack(input: $input, condition: $condition) {
            id
            pack {
                id
                name
                description
                thumbnailPath
                level
                bundledScenes {
                    items {
                        bundledScenes {
                            id
                            name
                            description
                            status
                            thumbnailPath
                            updatedAt
                            createdAt
                            mediaType
                            scenes {
                                items {
                                    scene {
                                        id
                                        hasBlurryBG
                                        format
                                        includeInPromptCatalog
                                        isAnimatable
                                        name
                                        optionsPerBlock
                                        owner
                                        path
                                        productSupportedSize
                                        sceneDescription
                                        selectedValuesOfBlocks
                                        status
                                        themes {
                                            main
                                            sub
                                        }
                                        brief {
                                            blocks
                                            description
                                        }
                                        thumbnailPath
                                        type
                                        viewsSupported
                                        updatedAt
                                        createdAt
                                    }
                                }
                            }
                        }
                    }
                }
                tags
                status
                order
                exampleImages
                createdAt
                updatedAt
                owner
            }
            product {
                id
                path
                name
                status
                productType
                thumbnailPath
                supportedViewType
                industryType
                productFamily
                size {
                    width
                    height
                    depth
                }
                assets {
                    url
                }
                dataForInvoice {
                    customProductComplexityRank
                    priceId
                    immersiveProductId
                    priceValue
                    priceCurrency
                }
                username
                createdAt
                updatedAt
                owner
            }
            status
            username
            createdAt
            updatedAt
            generatedPackPackId
            generatedPackProductId
            owner
        }
    }
`;
