import { AnalyticsEvents, track } from "../../../src/utils/AnalyticsUtils";

export interface UserLocation {
    "ip": string;
    "network": string;
    "version": string;
    "city": string;
    "region": string;
    "region_code": string;
    "country": string;
    "country_name": string;
    "country_code": string;
    "country_code_iso3": string;
    "country_capital": string;
    "country_tld": string;
    "continent_code": string;
    "in_eu": boolean;
    "postal": number | null;
    "latitude": number;
    "longitude": number;
    "timezone": string;
    "utc_offset": string;
    "country_calling_code": string;
    "currency": string;
    "currency_name": string;
    "languages": string;
    "country_area": number;
    "country_population": number;
    "asn": string;
    "org": string;
}

export async function getUserLocation(): Promise<UserLocation | undefined> {
    try {
        const getUserIpRes = await fetch('/api/ip').then((res) => res.json());
        let userLocation  = '';
        if (getUserIpRes) {
            userLocation = await fetch(`https://ipapi.co/${getUserIpRes.ip}/json/`).then((res) =>  res.json());
        }

        return userLocation as unknown as UserLocation;
    } catch (error) {
        console.error('Error in retrieving user location', error);
        track(AnalyticsEvents.GET_USER_LOCATION_FAILED);
        
        return;
    }
}