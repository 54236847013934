import { GeneratedPack, Pack, Product, Project } from '../API';
import { updateProject } from '../graphql/mutations';
import { Auth, API, Storage, graphqlOperation } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import { HubCallback, HubCapsule } from '@aws-amplify/core/lib-esm/Hub';
import AmplifyUtils from '../utils/AmplifyUtils';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { PackThumbnail } from '../../app/dashboard/stores/usePacksStore';
import { isThumbnailHasVideoType } from '../../app/dashboard/wizard/utils/wizardUpgradePlanData';
import S3UrlReplacement from '../utils/S3UrlReplacement';

export enum PresignedUrlUsage {
    download = 'download',
    upload = 'upload',
    both = 'both',
}

//class for handling project thumbnails
export class ImageDataHandler {
    //add constant for the key suffix
    private static readonly THUMBNAIL_KEY_SUFFIX = '_thumbnail';
    private static readonly CONSTRUCTING_IMAGE_KEY_SUFFIX = '_constructing_image';
    private static readonly MAX_CONSTRUCTING_IMAGES = 1000;

    private static readonly ACCESS_LEVEL = 'private';

    static readonly GET_PRESIGNED_URL = '/kartivappGeneratePresignedURL';

    private domainUrl: string = '';
    private user: CognitoUser | undefined = undefined;

    handleAuthEvent: HubCallback = (event: HubCapsule) => {
        const { payload } = event;

        switch (payload.event) {
            case 'signIn':
                this.user = payload.data;
                break;
            case 'signOut':
                this.user = undefined;
                break;
        }
    };

    constructor() {
        Hub.listen('auth', this.handleAuthEvent);

        Auth.currentAuthenticatedUser()
            .then((user) => {
                if (this.user == undefined) {
                    this.user = user;
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    private constructImageObjectKey(project: Project, imageId: string) {
        return project.id + '_' + imageId + ImageDataHandler.CONSTRUCTING_IMAGE_KEY_SUFFIX;
    }

    public async addConstructingImagelData(project: Project, imageId: string, thumbnailData: any, contentType: string): Promise<Project> {
        const objectKey = this.constructImageObjectKey(project, imageId);
        this.uploadImageData(objectKey, thumbnailData, contentType).then(() => {
            this.getConstructingImageData(project, imageId); //to store on the cache
        });

        let newImagesContstructingThumbnailIds: (string | null)[] = !!project.imagesContstructingThumbnailIds
            ? project.imagesContstructingThumbnailIds!
            : [];

        const imageIndex = newImagesContstructingThumbnailIds.indexOf(imageId);

        if (imageIndex >= 0) {
            //remove the old image
            newImagesContstructingThumbnailIds.splice(imageIndex, 1);
        } else {
            if (newImagesContstructingThumbnailIds.length >= ImageDataHandler.MAX_CONSTRUCTING_IMAGES) {
                //remove the oldest one
                if (newImagesContstructingThumbnailIds[0] != null) {
                    const objectKeyToRemove = this.constructImageObjectKey(project, newImagesContstructingThumbnailIds[0]);

                    this.deleteObject(objectKeyToRemove);
                }

                newImagesContstructingThumbnailIds.shift();
            }
        }

        newImagesContstructingThumbnailIds.push(imageId);
        return await this.updateProjectWithImagesConstrcutingThumbnailIds(project, newImagesContstructingThumbnailIds);
    }

    private async updateProjectWithImagesConstrcutingThumbnailIds(
        project: Project,
        newImagesContstructingThumbnailIds: (string | null)[],
    ): Promise<Project> {
        try {
            const res = await API.graphql(
                graphqlOperation(updateProject, {
                    input: { id: project.id, imagesContstructingThumbnailIds: newImagesContstructingThumbnailIds },
                }),
            );

            project = (res as GraphQLResult<any>).data.updateProject;
        } catch (error) {
            console.error(`error when trying to edit imagesContstructingThumbnailIds for ${project.id}`, error);
        }

        return project;
    }

    public async removeConstructingImagelData(project: Project, imageId: string) {
        const objectKey = this.constructImageObjectKey(project, imageId);
        await this.deleteObject(objectKey);

        const imageUrl = await this.getImageDownloadPath(imageId);

        const storageCache = await this.getStorageCache();
        await storageCache?.delete(imageUrl);

        let newImagesContstructingThumbnailIds: (string | null)[] = !!project.imagesContstructingThumbnailIds
            ? project.imagesContstructingThumbnailIds!
            : [];

        const imageIndex = newImagesContstructingThumbnailIds.indexOf(imageId);

        if (imageIndex >= 0) {
            newImagesContstructingThumbnailIds.splice(imageIndex, 1);

            this.updateProjectWithImagesConstrcutingThumbnailIds(project, newImagesContstructingThumbnailIds);
        }
    }

    public async deleteProjectThumbnailData(project: Project): Promise<boolean> {
        return await this.deleteObject(project.id + ImageDataHandler.THUMBNAIL_KEY_SUFFIX);
    }

    private async deleteObject(objectKey: string): Promise<boolean> {
        try {
            Storage.remove(objectKey, { level: ImageDataHandler.ACCESS_LEVEL });
            return true;
        } catch (error) {
            console.error(`failed in deleting object with key ${objectKey}`, error);
            return false;
        }
    }

    public async getProjectThumbnailData(project: Project): Promise<Blob> {
        try {
            console.log('project ' + project.name, project.id + ImageDataHandler.THUMBNAIL_KEY_SUFFIX);

            const storageCache = await this.getStorageCache();

            const imageId = project.id + ImageDataHandler.THUMBNAIL_KEY_SUFFIX;
            const cachedResponse = await storageCache?.match(imageId).then((response) => response?.blob());

            if (cachedResponse) {
                return cachedResponse;
            }

            const url = await Storage.get(project.id + ImageDataHandler.THUMBNAIL_KEY_SUFFIX, {
                level: ImageDataHandler.ACCESS_LEVEL,
                download: false,
            });
            const response = await fetch(url);
            await storageCache?.put(imageId, response.clone());

            return response.blob();
        } catch (error) {
            console.log(error);
            // console.error('failed in downloading project thumbnail ', error, project);
            return new Blob();
        }
    }

    public async fetchDataFromThumbnailPath(thumbnailPath: string, id: string): Promise<Blob> {
        const imageUrl = await this.getImageDownloadPath(id);
        const storageCache = await this.getStorageCache();
        const cachedResponse = await storageCache?.match(imageUrl).then((response) => {
            return response?.blob();
        });
        if (cachedResponse) {
            return cachedResponse;
        }

        try {
            return await this.fetchFileDataFromUrl(thumbnailPath, id);
        } catch (error) {
            return new Blob();
        }
    }

    public async uploadProductPNGThumbnailData(product: Product, thumbnailData: any): Promise<boolean> {
        if (this.user == undefined) {
            console.log('user is not logged in cannot upload product thumbnail');

            return false;
        }

        const presignedUrls = await this.getProductThumbnailPresingedUrl(PresignedUrlUsage.upload, product.id);
        if (presignedUrls.presignedUploadUrl != '') {
            const response = await fetch(presignedUrls.presignedUploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/png',
                },
                body: thumbnailData,
            });

            if (response.status == 200) {
                return true;
            }
        }

        return false;
    }

    public async getGeneralPresingedUrl(
        key: string,
        contentType: string,
        urlUsage: PresignedUrlUsage,
    ): Promise<{ presignedDownloadUrl: string; presignedUploadUrl: string }> {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fullFileName: key, urlUsage: urlUsage, contentType: contentType }),
        };

        return await this.remoteGetPresignedUrl(options);
    }

    private async remoteGetPresignedUrl(options: any): Promise<{ presignedDownloadUrl: string; presignedUploadUrl: string }> {
        const response = await API.post(AmplifyUtils.getRESTApiName(), ImageDataHandler.GET_PRESIGNED_URL, options);

        if (response.statusCode == undefined || response.statusCode == 200) {
            let downloadUrl = response.downloadUrl;
            let uploadUrl = response.uploadUrl;
            if (downloadUrl == undefined && response.body != undefined) {
                const parsedBody = JSON.parse(response.body);
                downloadUrl = parsedBody.downloadUrl;
                uploadUrl = parsedBody.uploadUrl;
            }
            return { presignedDownloadUrl: downloadUrl, presignedUploadUrl: uploadUrl };
        } else {
            console.error('failed in getting presinged url ', options, response);
        }

        return { presignedDownloadUrl: '', presignedUploadUrl: '' };
    }

    public async getGeneratedPackZipPresingedUrl(
        urlUsage: PresignedUrlUsage,
        generatedPackId: string,
    ): Promise<{ presignedDownloadUrl: string; presignedUploadUrl: string }> {
        return await this.getGeneratedPackPresingedUrl(urlUsage, generatedPackId, 'application/zip');
    }

    public async getGeneratedPackThumbnailPresingedUrl(
        urlUsage: PresignedUrlUsage,
        generatedPackId: string,
    ): Promise<{ presignedDownloadUrl: string; presignedUploadUrl: string }> {
        return await this.getGeneratedPackPresingedUrl(urlUsage, generatedPackId, 'image/png');
    }

    private async getGeneratedPackPresingedUrl(
        urlUsage: PresignedUrlUsage,
        generatedPackId: string,
        contentType: string,
    ): Promise<{ presignedDownloadUrl: string; presignedUploadUrl: string }> {
        const session = await Auth.userSession(this.user);
        const jwtToken: string = session.getIdToken().getJwtToken();

        const options = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: jwtToken,
            },
            body: JSON.stringify({
                generatedPackId: generatedPackId,
                Authorization: jwtToken,
                urlUsage: urlUsage,
                contentType: contentType,
                fileNameSuffix: contentType == 'image/png' ? 'thumbnail' : '',
            }),
        };

        return await this.remoteGetPresignedUrl(options);
    }

    public async getPackThumbnailData(pack: Pack): Promise<Blob> {
        if (this.user == undefined) {
            console.log('user is not logged in');

            return new Blob();
        }

        const thumbnailUrlForCheckingCache = await this.getImageDownloadPath(pack.id);
        const storageCache = await this.getStorageCache();
        const cachedResponse = await storageCache?.match(thumbnailUrlForCheckingCache).then((response) => {
            return response?.blob();
        });

        if (cachedResponse) {
            return cachedResponse;
        }

        try {
            if (pack.thumbnailPath && pack.thumbnailPath != '') {
                try {
                    const thumbnailId = pack.id;
                    return await this.fetchFileDataFromUrl(pack.thumbnailPath, thumbnailId);
                } catch (error) {
                    return new Blob();
                }
            } else {
                console.error('thumbnail path for pack invalid', pack.thumbnailPath);
            }

            return new Blob();
        } catch (error) {
            console.error('failed in getting thumbnail data for pack ', error);
            return new Blob();
        }

    }

    public async getPackExampleImageData(packId: string, imageUrl: string, imageName: string): Promise<Blob> {
        if (this.user == undefined) {
            console.log('user is not logged in');

            return new Blob();
        }

        const thumbnailUrlForCheckingCache = await this.getImageDownloadPath(packId + '-example-image-' + imageName);
        const storageCache = await this.getStorageCache();
        const cachedResponse = await storageCache?.match(thumbnailUrlForCheckingCache).then((response) => {
            return response?.blob();
        });

        if (cachedResponse) {
            return cachedResponse;
        }

        try {
            try {
                const thumbnailId = packId + '-example-image-' + imageName;
                const imageUrlForFetch  = S3UrlReplacement.replaceS3UrlWithCloudfrontUrl(imageUrl);

                return await this.fetchFileDataFromUrl(imageUrlForFetch, thumbnailId);
            } catch (error) {
                return new Blob();
            }
        } catch (error) {
            console.error('failed in getting example image data for pack ', error);
            return new Blob();
        }

    }

    public async getGeneratedPackThumbnailDataWithType(generatedPack: GeneratedPack): Promise<PackThumbnail> {

        const thumbnailBlob = await this.getGeneratedPackThumbnailData(generatedPack);
        const generatedPackThumbnail = {
            type: thumbnailBlob.type.startsWith('video') ? 'video' : 'image',
            url: URL.createObjectURL(thumbnailBlob)
        }

        return generatedPackThumbnail;
    }

    public async getGeneratedPackThumbnailData(generatedPack: GeneratedPack): Promise<Blob> {
        if (this.user == undefined) {
            console.log('user is not logged in');

            return new Blob();
        }

        const imageUrlForCheckingCache = await this.getImageDownloadPath(generatedPack.id);
        const storageCache = await this.getStorageCache();
        const cachedResponse = await storageCache?.match(imageUrlForCheckingCache).then((response) => {
            return response?.blob();
        });

        if (cachedResponse) {
            return cachedResponse;
        }
        
        const imagePresignedDownloadUrls = await this.getGeneratedPackThumbnailPresingedUrl(PresignedUrlUsage.both, generatedPack.id);

        try {
            if (imagePresignedDownloadUrls && imagePresignedDownloadUrls.presignedDownloadUrl && imagePresignedDownloadUrls.presignedDownloadUrl != '') {
                try {
                    const imageId = generatedPack.id;
                    return await this.fetchFileDataFromUrl(imagePresignedDownloadUrls.presignedDownloadUrl, imageId);
                } catch (error) {
                    return new Blob();
                }
            } else {
                console.error('presinged url for generated pack thumbnail invalid', imagePresignedDownloadUrls);
            }

            return new Blob();
        } catch (error) {
            console.error('failed in getting presinged url for generated pack thumbnail ', error, generatedPack);
            return new Blob();
        }
    }

    public async getProductThumbnailPresingedUrl(
        urlUsage: PresignedUrlUsage,
        productId: string,
        contentType: string = 'image/png',
    ): Promise<{ presignedDownloadUrl: string; presignedUploadUrl: string }> {
        const session = await Auth.userSession(this.user);
        const jwtToken: string = session.getIdToken().getJwtToken();

        const options = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: jwtToken,
            },
            body: JSON.stringify({ productId: productId, Authorization: jwtToken, urlUsage: urlUsage, contentType: contentType }),
        };

        return await this.remoteGetPresignedUrl(options);
    }

    public async deleteCachedImagePrivateData(key: string): Promise<void> {
        const keyToUseAsImageId = key.replace(/\//g, '_');
        const imageUrl = await this.getImageDownloadPath(keyToUseAsImageId);

        const storageCache = await this.getStorageCache();

        storageCache?.delete(imageUrl);
    }

    public async getImagePrivateDataAndUrl(key: string, contentType: string): Promise<{ blob: Blob; url: string }> {
        const blobAndOptionalUrl = await this.getPrivateImageDataAndOptionalUrl(key, contentType);

        if (blobAndOptionalUrl.url) {
            return blobAndOptionalUrl;
        }

        const presignedUrls = await this.getGeneralPresingedUrl(key, contentType, PresignedUrlUsage.download);

        if (presignedUrls.presignedDownloadUrl != '') {
            return {
                blob: blobAndOptionalUrl.blob,
                url: presignedUrls.presignedDownloadUrl,
            };
        } else {
            console.error('presinged url for ' + key + ' is invalid', presignedUrls);
        }

        return blobAndOptionalUrl;
    }

    private async getPrivateImageDataAndOptionalUrl(key: string, contentType: string): Promise<{ blob: Blob; url: string }> {
        //replace / occurences with _ so it will be valid as queary param
        const keyToUseAsImageId = key.replace(/\//g, '_');
        const imageUrl = await this.getImageDownloadPath(keyToUseAsImageId);

        const storageCache = await this.getStorageCache();
        const cachedResponse = await storageCache?.match(imageUrl).then((response) => {
            return response?.blob();
        });
        if (cachedResponse) {
            return { blob: cachedResponse, url: '' };
        }

        try {
            const presignedUrls = await this.getGeneralPresingedUrl(key, contentType, PresignedUrlUsage.download);
            if (presignedUrls.presignedDownloadUrl != '') {
                try {
                    return {
                        blob: await this.fetchFileDataFromUrl(presignedUrls.presignedDownloadUrl, keyToUseAsImageId),
                        url: presignedUrls.presignedDownloadUrl,
                    };
                } catch (error) {
                    return { blob: new Blob(), url: '' };
                }
            } else {
                console.error('presinged url for ' + key + ' is invalid', presignedUrls);
            }

            return { blob: new Blob(), url: '' };
        } catch (error) {
            console.error('failed in getting presinged url for ' + key, error);
            return { blob: new Blob(), url: '' };
        }
    }

    public async getPrivateImageData(key: string, contentType: string): Promise<Blob> {
        return (await this.getPrivateImageDataAndOptionalUrl(key, contentType)).blob;
    }

    public async getProductThumbnailData(product: Product): Promise<Blob> {
        if (this.user == undefined) {
            console.log('user is not logged in');

            return new Blob();
        }

        if (product.thumbnailPath) {
            return await this.fetchDataFromThumbnailPath(product.thumbnailPath, product.id);
        }

        const imageUrl = await this.getImageDownloadPath(product.id);

        const storageCache = await this.getStorageCache();
        const cachedResponse = await storageCache?.match(imageUrl).then((response) => {
            return response?.blob();
        });
        if (cachedResponse) {
            return cachedResponse;
        }

        try {
            const presignedUrls = await this.getProductThumbnailPresingedUrl(PresignedUrlUsage.download, product.id);
            if (presignedUrls.presignedDownloadUrl != '') {
                try {
                    const imageId = product.id;
                    return await this.fetchFileDataFromUrl(presignedUrls.presignedDownloadUrl, imageId);
                } catch (error) {
                    return new Blob();
                }
            } else {
                console.error('presinged url for product thumbnail invalid', presignedUrls);
            }

            return new Blob();
        } catch (error) {
            console.error('failed in getting presinged url for product thumbnail ', error, product);
            return new Blob();
        }
    }

    private async fetchFileDataFromUrl(url: string, imageId: string): Promise<Blob> {
        try {
            const imageUrl = await this.getImageDownloadPath(imageId);
            const storageCache = await this.getStorageCache();

            // Fetch the file data using the pre-signed URL
            const response = await this.downloadImage(url);

            await storageCache?.put(imageUrl, response.clone());

            // Check if the response is successful
            if (!response.ok) {
                throw new Error(`Error fetching file data: ${response.statusText}`);
            }

            // Read the file data as a Blob
            const data = await response.blob();

            return data;
        } catch (error) {
            console.error('Error fetching file data:', error);
            throw error;
        }
    }

    public async getConstructingImagesData(project: Project): Promise<{ storyId: string; thumbnail: string }[]> {
        let constructingImagesData = [];
        if (!!project.imagesContstructingThumbnailIds) {
            for (let i = project.imagesContstructingThumbnailIds.length - 1; i >= 0; i--) {
                const imageId = project.imagesContstructingThumbnailIds[i];
                if (imageId == null) {
                    continue;
                }
                try {
                    const data = await this.getConstructingImageData(project, imageId);
                    constructingImagesData.push(data);
                } catch (error) {
                    console.error(`failed in downloading constructing image ${imageId} in project ${project.id}`, error);
                }
            }
        }

        return constructingImagesData;
    }

    public async getConstructingImageData(project: Project, imageId: string): Promise<{ storyId: string; thumbnail: string }> {
        const storageCache = await this.getStorageCache();

        const imageUrl = await this.getImageDownloadPath(imageId);

        const cachedResponse = await storageCache?.match(imageUrl).then((response) => response?.blob());

        if (cachedResponse) {
            if (cachedResponse.size <= 1000) {
                console.log('cachedResponse size is problematic,  deleting from cache', imageUrl);
                await storageCache?.delete(imageUrl);
            } else {
                return {
                    storyId: imageId,
                    thumbnail: URL.createObjectURL(cachedResponse),
                };
            }
        }

        let output: any = {};

        if (!!project.imagesContstructingThumbnailIds) {
            try {
                const url = await Storage.get(this.constructImageObjectKey(project, imageId), {
                    level: ImageDataHandler.ACCESS_LEVEL,
                    download: false,
                });
                const response = await this.downloadImage(url);
                const clonedResponse = response.clone();
                const responseBlob = await response.blob();
                if (responseBlob.size <= 1000) {
                    console.error(`blob problematic size of  ${imageId} in project ${project.id} size is ${responseBlob.size}`);
                } else {
                    await storageCache?.put(imageUrl, clonedResponse);
                    return {
                        storyId: imageId,
                        thumbnail: URL.createObjectURL(responseBlob),
                    };
                }
            } catch (error) {
                console.error(`failed in downloading constructing image ${imageId} in project ${project.id}`, error);
            }
        }
        return output;
    }

    public async setProjectThumbnailData(project: Project, thumbnailData: any, contentType: string): Promise<boolean> {
        const objectKey = project.id + ImageDataHandler.THUMBNAIL_KEY_SUFFIX;
        return await this.uploadImageData(objectKey, thumbnailData, contentType);
    }

    private async getImageDownloadPath(imageId: string): Promise<string> {
        if (this.domainUrl == '') {
            const response = await fetch('/api/domain');
            const data = await response.json();
            this.domainUrl = data.domainUrl;
        }

        return `${this.domainUrl}/api/download-image?imageId=${imageId}`;
    }

    private async getStorageCache() {
        return caches ? await caches.open('kartiv-cache-storage') : undefined;
    }

    private async uploadImageData(objectKey: string, imageData: any, contentType: string): Promise<boolean> {
        const userId = this.user == undefined ? '' : this.user.getUsername();
        console.log('uploading image data with key ' + objectKey + ' and user ' + userId);

        try {
            const uploadResult = await Storage.put(objectKey, imageData, {
                contentType: contentType,
                level: ImageDataHandler.ACCESS_LEVEL,
            });
            console.log(`Upload ${objectKey} succeeded:`, uploadResult);

            return true;
        } catch (error) {
            console.error(`Upload ${objectKey} failed:`, error);
            return false;
        }
    }

    private async downloadImage(url: string) {
        try {
            const response = await fetch(url);

            return response;
        } catch (error) {
            console.error('Failed in downloading image:', error);
            throw error;
        }
    }
}
