'use client';

import { faPalette, faSuitcase, faCode, faShop, faMegaphone, faHandshake, faShapes, faBox } from '@fortawesome/sharp-regular-svg-icons';

export interface StepInDB {
    id: number;
    data?: string;
    isSubmited: boolean;
}

export interface OnboardingFormComponentProps {
    currentStep: number;
    setCurrentStep: (currentStep: number) => void;
    submitedForms: number[];
    setSubmitedForms: any;
    skippedForms: number[];
    userDataFormType: string;
}

export enum TooltipType {
    NEW_PROJECT = 'new-project',
    NEW_PRODUCT = 'new-product',
    PROJECT_EDITOR = 'project-editor',
}

export enum UserAttributesType {
    ROLES = 'roles',
    GOALS = 'goals',
    PRODUCTS = 'products',
    SUBSCRIPTION = 'subscription',
    USER_DATA = 'userData',
    LIMITATION = 'limitation'
}

export const goals = [
    {
        id: 0,
        title: 'Improve website conversion rates',
        icon: '',
    },
    {
        id: 1,
        title: 'Increase social media engagement',
        icon: '',
    },
    {
        id: 2,
        title: 'Enhance email campaigns',
        icon: '',
    },
    {
        id: 3,
        title: 'Optimize product images Owner',
        icon: '',
    },
    {
        id: 4,
        title: 'Strengthen brand identity',
        icon: '',
    },
    {
        id: 5,
        title: 'Generate promotional materials',
        icon: '',
    },
    {
        id: 6,
        title: 'Streamline content creation workflows',
        icon: '',
    },
    {
        id: 7,
        title: 'Produce video content',
        icon: '',
    },
];

export const roles = [
    {
        id: 0,
        title: 'Design',
        icon: faPalette,
    },
    {
        id: 1,
        title: 'Management',
        icon: faSuitcase,
    },
    {
        id: 2,
        title: 'Engineering',
        icon: faCode,
    },
    {
        id: 3,
        title: 'Business Owner',
        icon: faShop,
    },
    {
        id: 4,
        title: 'Marketing',
        icon: faMegaphone,
    },
    {
        id: 5,
        title: 'Sales',
        icon: faHandshake,
    },
    {
        id: 6,
        title: 'Content',
        icon: faShapes,
    },
    {
        id: 7,
        title: 'Product',
        icon: faBox,
    },
];
