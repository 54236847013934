import { CognitoUser } from '@aws-amplify/auth';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { isObservable, Observable, firstValueFrom } from 'rxjs';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
    getPromptCatalog2LevelElementsSubCategories,
    listPacksWithDescendantsData,
    generatedPackByUsernameWithDescendantsData,
} from '../graphqlCustomKartiv/kartivQueries';
import {
    getStory,
    getActiveSpace,
    getActiveProduct,
    getProject,
    getProduct,
    spaceByUsername,
    projectByUsername,
    productByUsernameAndStatus,
    listScenes,
    listProductSamples,
    getUser,
} from '../graphql/queries';
import {
    deleteStory,
    createStory,
    updateStory,
    createTrackedProgressItem,
    createSpace,
    createFolder,
    createProject,
    updateActiveSpace,
    createActiveSpace,
    deleteActiveProduct,
    updateProduct,
    createProduct,
    updateActiveProduct,
    createActiveProduct,
    deleteProject,
    deleteProduct,
    deleteTrackedProgressItem,
    updateProject,
    createUser,
    updateUser,
    updateTrackedProgressItem,
    createPromptHistory,
    createGeneratedPack,
} from '../graphql/mutations';
import {
    Space,
    Project,
    Story,
    TrackedProgressItem,
    StoryStatus,
    CreateStoryInput,
    Scene,
    UpdateStoryInput,
    DeleteStoryInput,
    CreateProjectInput,
    SceneStatus,
    UpdateProductInput,
    Product,
    ProductStatus,
    BriefInput,
    ProductSample,
    User,
    UpdateUserInput,
    UserAttributeInput,
    ProductType,
    UpdateTrackedProgressItemInput,
    DeleteTrackedProgressItemInput,
    LimitationTypeInput,
    ProjectStatus,
    PromptCatalog,
    PromptHistory,
    CreatePromptHistoryInput,
    TrialSubscriptionStatus,
    SceneType,
    ModelSceneFilterInput,
    Pack,
    PackStatus,
    ModelPackFilterInput,
    GeneratedPack,
} from '../API';
import { ImageDataHandler, PresignedUrlUsage } from '../data/ImageDataHandler';
import AmplifyUtils from '../../src/utils/AmplifyUtils';
import { track, AnalyticsEvents, addCustomAnalyticsUserAttributes } from '../utils/AnalyticsUtils';
import { UserAttributesType, StepInDB } from '../../app/onboarding/utils/types';
import { stripe, StripeSubscriptionWithPlan } from '../../app/subscription/utils/types';
import Stripe from 'stripe';
import { ProjectType } from '../../app/dashboard/types';
import { CreateProjectDataInput } from '../../app/dashboard/wizard/utils/wizardData';
import { UserLocation, getUserLocation } from '../../app/dashboard/utils/getUserLocation';
import { EnvironmentUtils } from '../utils/EnvironmentUtils';
import { PackThumbnail } from '../../app/dashboard/stores/usePacksStore';
import { createGeneratedPackWithDescendantsData } from '../graphqlCustomKartiv/kartivMutations';

export class DataOperations {
    //static private constatnt that store the remote functions url

    static readonly CREATE_FOLDER_AND_ACTIVES_SPACE_API_PATH = '/kartivappCreateActiveSpaceAndFolder';
    static readonly DELETE_FOLDER_REIMAGINE_PRIVATE_DATA_API_PATH = '/kartivappDeleteReimaginePrivateData';
    static readonly PROMPT_CATALOG_DEV_ID = 'baa87ca2-e8be-450e-9ef6-54a04d0f0fbe';
    static readonly PROMPT_CATALOG_PRODUCTION_ID = 'cc7fa7ca-cfef-4281-a064-8582de96a1a1';

    private imageDataHandler = new ImageDataHandler();

    public constructor() {}
    public async getUserSpaces(user: CognitoUser): Promise<Space[]> {
        const fetchSpacesResult = await this.extractResultFromResultOrObservable(
            await API.graphql(
                graphqlOperation(spaceByUsername, {
                    username: user?.getUsername(),
                }),
            ),
        );
        const spaces = (fetchSpacesResult as GraphQLResult<any>).data.spaceByUsername.items;
        return spaces;
    }
    public async activateUserSpace(user: CognitoUser, spaceIdToActivate: string = '') {
        if (spaceIdToActivate == '') {
            const userSpaces = await this.getUserSpaces(user);
            if (userSpaces.length > 0) {
                spaceIdToActivate = userSpaces[0].id;
            }
        }
        const userSpaces = await this.getUserSpaces(user);
        if (spaceIdToActivate.length > 0) {
            const getActivateSpaceRes = await API.graphql(graphqlOperation(getActiveSpace, { owner: user.getUsername() }));
            const getActiveSpaceResData = (getActivateSpaceRes as GraphQLResult<any>).data.getActiveSpace;
            if (getActiveSpaceResData == null) {
                //never been activated
                await API.graphql(
                    graphqlOperation(createActiveSpace, { input: { owner: user.getUsername(), activeSpaceSpaceId: spaceIdToActivate } }),
                );
            } else {
                await API.graphql(
                    graphqlOperation(updateActiveSpace, { input: { owner: user?.getUsername(), activeSpaceSpaceId: spaceIdToActivate } }),
                );
            }
        }
    }
    public async createSpaceWithFolderForUser(user: CognitoUser, activate: boolean = false) {
        const createFolderRes = await API.graphql(graphqlOperation(createFolder, { input: { name: 'Root' } }));
        const createFolderResData = (createFolderRes as GraphQLResult<any>).data.createFolder;
        let userGivenName = '';
        user.getUserAttributes(async (err: any, attributes: any[] | undefined) => {
            if (attributes == undefined) {
                console.log('attributes is undefined');
                return '';
            }
            if (err) {
                console.error(err);
                return '';
            }
            const givenNameAttribute = attributes.find((attribute) => attribute.getName() === 'given_name');
            if (givenNameAttribute) {
                userGivenName = givenNameAttribute.getValue();
                const createSpaceRes = await API.graphql(
                    graphqlOperation(createSpace, {
                        input: {
                            name: userGivenName + "ֿֿ's Space",
                            spaceRootFolderId: createFolderResData.id,
                            username: user.getUsername(),
                        },
                    }),
                );
                const createSpaceResData = (createSpaceRes as GraphQLResult<any>).data.createSpace;
                if (activate) {
                    await this.activateUserSpace(user, createSpaceResData.id);
                }
            } else {
                console.log('Attribute given_name not found');
            }
        });
    }
    public async getActiveSpace(user: CognitoUser): Promise<string | null> {
        const getActivateSpaceRes = await API.graphql(graphqlOperation(getActiveSpace, { owner: user.getUsername() }));
        const getActiveSpaceResData = (getActivateSpaceRes as GraphQLResult<any>).data.getActiveSpace;
        if (getActiveSpaceResData && getActiveSpaceResData.space) return getActiveSpaceResData.space.name;
        return null;
    }
    public async getActiveSpaceRootFolder(user: CognitoUser): Promise<string> {
        console.log('getActiveSpaceRootFolder');
        const getActivateSpaceRes = await API.graphql(graphqlOperation(getActiveSpace, { owner: user.getUsername() }));
        const getActiveSpaceResData = (getActivateSpaceRes as GraphQLResult<any>).data.getActiveSpace;
        console.log('active space root folder', getActiveSpaceResData?.space.rootFolder.id);
        return getActiveSpaceResData?.space.rootFolder.id;
    }
    public async createNewProject(
        user: CognitoUser,
        folderId: string = '',
        projectData?: CreateProjectDataInput,
    ): Promise<Project | undefined> {
        console.log('createProject');
        const activeFolderId: string = folderId === '' ? await this.getActiveSpaceRootFolder(user) : folderId;
        if (activeFolderId.length > 0) {
            const createProjectInput: CreateProjectInput = {
                folderProjectsId: activeFolderId,
                type: 'Project',
                username: user.getUsername(),
                userBrief: {
                    description: '',
                    blocks: [],
                },
                ...(projectData && { ...projectData }),
            };
            const createProjecrRes = await API.graphql(graphqlOperation(createProject, { input: createProjectInput }));
            console.log('createProjecrRes', createProjecrRes);
            const project = (createProjecrRes as GraphQLResult<any>).data.createProject;
            track(AnalyticsEvents.PROJECT_CREATED, {
                projectId: project.id,
                projectType: projectData?.wizardDialogData ? ProjectType.WIZARD : ProjectType.PROMPT,
            });
            return project;
        }
        return undefined;
    }
    public async editProjectTitle(projectId: string, title: string): Promise<boolean> {
        console.log('editProjectTitle', projectId);
        try {
            API.graphql(graphqlOperation(updateProject, { input: { id: projectId, name: title } }));
            track(AnalyticsEvents.PROJECT_TITLE_EDIT, { projectId: projectId, title: title });
            return true;
        } catch (error) {
            console.error(`error when trying to edit project title for ${projectId}`, error);
            return false;
        }
    }
    public async editProjectUserBrief(projectId: string, userBriefDescription: string): Promise<boolean> {
        console.log('editProjectUserBrief', projectId);
        try {
            API.graphql(graphqlOperation(updateProject, { input: { id: projectId, userBrief: { description: userBriefDescription } } }));
            return true;
        } catch (error) {
            console.error(`error when trying to edit user brief in project for ${projectId}`, error);
            return false;
        }
    }

    public async updateProjectSelectedValuesOfUserBriefBlocks(
        projectId: string,
        selectedValuesOfUserBriefBlocks: string[],
    ): Promise<boolean> {
        try {
            API.graphql(graphqlOperation(updateProject, { input: { id: projectId, selectedValuesOfUserBriefBlocks } }));
            return true;
        } catch (error) {
            console.error(`error when trying to update selected values of user brief blocks in project for ${projectId}`, error);
            return false;
        }
    }

    public async createActiveSpaceAndFolder(user: CognitoUser, folderName: string = 'Root'): Promise<Response | false> {
        console.log('createActiveSpaceAndFolder');
        try {
            let userGivenName = user.getUsername();
            const idToken = (await Auth.userSession(user)).getIdToken();
            try {
                const attributes = await Auth.userAttributes(user);
                if (attributes == undefined) {
                    console.log('attributes is undefined');
                }
                console.log('attributes', attributes);
                const givenNameAttribute = attributes.find((attribute) => attribute.getName() === 'given_name');
                if (givenNameAttribute) {
                    userGivenName = givenNameAttribute.getValue();
                }
            } catch (error) {
                const attributes = idToken.payload; // for google users
                userGivenName = attributes.given_name[0];
            }
            console.log('userGivenName', userGivenName);
            const jwtToken: string = idToken.getJwtToken();
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: jwtToken,
                },
                body: JSON.stringify({
                    folderName: folderName,
                    spaceName: userGivenName + "'s Space",
                    Authorization: jwtToken,
                    createProductsFromSamplesState: true,
                }),
            };
            const response = await API.post(
                AmplifyUtils.getRESTApiName(),
                DataOperations.CREATE_FOLDER_AND_ACTIVES_SPACE_API_PATH,
                options,
            );
            console.dir(response);
            return response;
        } catch (error) {
            console.error('error createActiveSpaceAndFolder ', error);
            return false;
        }
    }

    public async getGeneratedPacksOfUser(user: CognitoUser): Promise<GeneratedPack[]> {
        try {
            const fetchGeneratedPacksResult = await this.extractResultFromResultOrObservable(
                await API.graphql(
                    graphqlOperation(generatedPackByUsernameWithDescendantsData, {
                        username: user.getUsername(),
                    }),
                ),
            );
            const generatedPacks = (fetchGeneratedPacksResult as GraphQLResult<any>).data.generatedPackByUsername.items;
            return generatedPacks;
        } catch (error) {
            console.error('error getGeneratedPacksOfUser', error);
            return [];
        }
    }

    public async createGeneratedPack(packId: string, productId: string, user: CognitoUser): Promise<GeneratedPack | undefined> {
        try {
            const createGeneratedPackInput = {
                username: user.getUsername(),
                status: 'DRAFT',
                generatedPackPackId: packId,
                generatedPackProductId: productId,
            }
            const createGeneratedPackResult = await this.extractResultFromResultOrObservable(
                await API.graphql(
                    graphqlOperation(createGeneratedPackWithDescendantsData, {
                        input: createGeneratedPackInput
                    }),
                ),
            );
            const createdGeneratedPack = (createGeneratedPackResult as GraphQLResult<any>).data.createGeneratedPack;

            return createdGeneratedPack;
        } catch (error) {
            console.error('error createGeneratedPack', error);
            return;
        }
    }

    public async getLastUpdatedUserProjectsInSpace(user: CognitoUser): Promise<Project[]> {
        const rootFolderInUserSpace = await this.getActiveSpaceRootFolder(user);
        const fetchProjectsResult = await this.extractResultFromResultOrObservable(
            await API.graphql(
                graphqlOperation(projectByUsername, {
                    username: user.getUsername(),
                    filter: { folderProjectsId: { eq: rootFolderInUserSpace } },
                }),
            ),
        );
        const projectsByUsername = (fetchProjectsResult as GraphQLResult<any>).data.projectByUsername.items;
        //sort projectsByList by updatedAt
        projectsByUsername.sort((a: { updatedAt: string }, b: { updatedAt: string }) => {
            //convert string to date
            //
            const aAsDate = new Date(a.updatedAt);
            const bAsDate = new Date(b.updatedAt);
            if (aAsDate < bAsDate) {
                return 1;
            } else if (aAsDate > bAsDate) {
                return -1;
            } else {
                return 0;
            }
        });
        const sortedProjectsByDisabledField = projectsByUsername.every(
            (project: Project) => project.status !== null && project.status !== undefined,
        )
            ? projectsByUsername.sort(
                  (a: Project, b: Project) => Number(a.status === ProjectStatus.INACTIVE) - Number(b.status === ProjectStatus.INACTIVE),
              )
            : projectsByUsername;
        console.log('projectsByUsername sorted', sortedProjectsByDisabledField);
        return sortedProjectsByDisabledField;
    }

    public async getAllPacksWithDescendantsData(statuses: PackStatus[]): Promise<Pack[]> {
        const filter: ModelPackFilterInput = {
            or: statuses.map((status) => ({
                status: {
                    eq: status,
                },
            })),
        };

        try {
            const listPackResult = await this.extractResultFromResultOrObservable(
                await API.graphql(
                    graphqlOperation(listPacksWithDescendantsData, {
                        filter: filter,
                        limit: 1000,
                    }),
                ),
            );

            console.log('listPackResult', listPackResult);

            const packsList = (listPackResult as GraphQLResult<any>).data.listPacks;
            let packs = packsList.items;

            //order packs by the order field of the pack, lower order first. Treat null values as max value
            packs = packs.sort((a: { order: number }, b: { order: number }) => {
                const orderA = a.order ?? Number.MAX_SAFE_INTEGER;
                const orderB = b.order ?? Number.MAX_SAFE_INTEGER;
                return orderA - orderB;
            });

            return packs;
        } catch (error) {
            console.error('error getAllPacksWithDescendantsData', error);
            return [];
        }
    }

    public async getAllScenes(
        statuses: SceneStatus[],
        typeToExclude?: SceneType,
        limit: number = 1000,
        nextToken?: string,
    ): Promise<[Scene[], string?]> {
        const filter: ModelSceneFilterInput = {
            or: statuses.map((status) => ({
                status: {
                    eq: status,
                },
            })),
            type: {
                ne: typeToExclude,
            },
        };

        try {
            const listScenesResult = await this.extractResultFromResultOrObservable(
                await API.graphql(
                    graphqlOperation(listScenes, {
                        filter: filter,
                        nextToken: nextToken,
                        limit: limit,
                    }),
                ),
            );
            const scenesList = (listScenesResult as GraphQLResult<any>).data.listScenes;
            const scenes = scenesList.items;
            const retNextToken = scenesList.nextToken;

            return [scenes, retNextToken];
        } catch (error) {
            console.error('error getAllScenes', error);
            return [[], undefined];
        }
    }

    public async getGeneratedPackZipDataPresignedUrls(generatedPack: GeneratedPack): Promise<{ upload: string; download: string }> {
        const presignedURLS = await this.imageDataHandler.getGeneratedPackZipPresingedUrl(PresignedUrlUsage.both, generatedPack.id);

        return { upload: presignedURLS.presignedUploadUrl, download: presignedURLS.presignedDownloadUrl };
    }
    public async getGeneratedPackThumbnailPresignedUrls(generatedPack: GeneratedPack): Promise<{ upload: string; download: string }> {
        const presignedURLS = await this.imageDataHandler.getGeneratedPackThumbnailPresingedUrl(PresignedUrlUsage.both, generatedPack.id);

        return { upload: presignedURLS.presignedUploadUrl, download: presignedURLS.presignedDownloadUrl };
    }

    public async getPackThumbnail(pack: Pack): Promise<Blob> {
        return this.imageDataHandler.getPackThumbnailData(pack);
    }

    public async getPackExampleImage(packId: string, imageUrl: string, imageName: string): Promise<Blob> {
        return this.imageDataHandler.getPackExampleImageData(packId, imageUrl, imageName);
    }

    public async getGeneratedPackThumbnail(generatedPack: GeneratedPack): Promise<PackThumbnail> {
        return this.imageDataHandler.getGeneratedPackThumbnailDataWithType(generatedPack);
    }

    public async getProductThumbnail(product: Product): Promise<Blob> {
        return this.imageDataHandler.getProductThumbnailData(product);
    }
    public async getProductThumbnailPresingedUrl(productId: string): Promise<{ presignedDownloadUrl: string; presignedUploadUrl: string }> {
        return this.imageDataHandler.getProductThumbnailPresingedUrl(PresignedUrlUsage.download, productId);
    }

    public async getDataFromThumbnailPath(thumbnailPath: string, id: string): Promise<Blob> {
        return this.imageDataHandler.fetchDataFromThumbnailPath(thumbnailPath, id);
    }

    public async getImagePrivateData(key: string): Promise<Blob> {
        return this.imageDataHandler.getPrivateImageData(key, 'image/png');
    }

    public async getImagePrivateDataAndUrl(key: string): Promise<{ blob: Blob; url: string }> {
        return this.imageDataHandler.getImagePrivateDataAndUrl(key, 'image/png');
    }

    public async getGeneralPresignedUrls(s3key: string, contentType: string): Promise<{ upload: string; download: string }> {
        const presignedURLS = await this.imageDataHandler.getGeneralPresingedUrl(s3key, contentType, PresignedUrlUsage.both);

        return { upload: presignedURLS.presignedUploadUrl, download: presignedURLS.presignedDownloadUrl };
    }

    public async deleteReimagineImagePrivateData(props: {
        user: CognitoUser;
        projectId: string;
        storyId: string;
        s3Key: string;
    }): Promise<void> {
        const cachedImageDeletionPromise = this.imageDataHandler.deleteCachedImagePrivateData(props.s3Key);

        const imageId = props.s3Key.split('/').pop();

        const deleteRemotePromise = this.deleteReimagineRemotePrivateData({
            user: props.user,
            projectId: props.projectId,
            storyId: props.storyId,
            imageId: imageId,
        });
        await Promise.all([cachedImageDeletionPromise, deleteRemotePromise]);
    }

    public async getProductsByUsername(username: string, status: ProductStatus): Promise<Product[]> {
        const productsByUsernameResult = await this.extractResultFromResultOrObservable(
            await API.graphql(
                graphqlOperation(productByUsernameAndStatus, {
                    username: username,
                    status: { eq: status },
                }),
            ),
        );
        return (productsByUsernameResult as GraphQLResult<any>).data.productByUsernameAndStatus.items;
    }
    public async getDisplayableUserProducts(user: CognitoUser): Promise<Product[]> {
        const [
            availableProductsResult,
            inReviewProductsResult,
            inactiveProductsResult,
            inProgressProductsResult,
            paymentWaitingResult,
            paymentReceivedResult,
        ] = await Promise.all([
            this.getProductsByUsername(user.getUsername(), ProductStatus.AVAILABLE),
            this.getProductsByUsername(user.getUsername(), ProductStatus.IN_REVIEW),
            this.getProductsByUsername(user.getUsername(), ProductStatus.INACTIVE),
            this.getProductsByUsername(user.getUsername(), ProductStatus.DESIGN_IN_PROGRESS),
            this.getProductsByUsername(user.getUsername(), ProductStatus.WAITING_FOR_PAYMENT),
            this.getProductsByUsername(user.getUsername(), ProductStatus.PAYMENT_RECEIVED),
        ]);
        const availbleAndReviewedProducts = [
            ...availableProductsResult,
            ...inReviewProductsResult,
            ...inactiveProductsResult,
            ...inProgressProductsResult,
            ...paymentWaitingResult,
            ...paymentReceivedResult,
        ];
        const sortedAvailbleAndReviewedProducts = availbleAndReviewedProducts.every(
            (product) => product.status !== null && product.status !== undefined,
        )
            ? availbleAndReviewedProducts.sort(
                  (a, b) => Number(a.status === ProductStatus.INACTIVE) - Number(b.status === ProductStatus.INACTIVE),
              )
            : availbleAndReviewedProducts;
        return sortedAvailbleAndReviewedProducts;
    }
    public async getAllProductSamples(): Promise<ProductSample[]> {
        const listProductSamplesResult = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(listProductSamples, {})),
        );
        const productSamples = (listProductSamplesResult as GraphQLResult<any>).data.listProductSamples.items;
        return productSamples;
    }
    public async getActiveUserProduct(
        user: CognitoUser,
        availableOrReviewedUserProducts: Product[] | undefined = undefined,
    ): Promise<Product | undefined> {
        const getActivateProductRes = await API.graphql(graphqlOperation(getActiveProduct, { owner: user.getUsername() }));
        const getActivateProductResData = (getActivateProductRes as GraphQLResult<any>).data.getActiveProduct;
        if (getActivateProductResData == null) {
            const userProducts =
                availableOrReviewedUserProducts == undefined
                    ? await this.getDisplayableUserProducts(user)
                    : availableOrReviewedUserProducts!;
            if (userProducts.length > 0) {
                //loop on userProducts and find first product that is available in userProducts
                const firstIndexOfAvailableProduct = userProducts.indexOf(
                    userProducts.find((product) => product.status == ProductStatus.AVAILABLE)!,
                );
                if (firstIndexOfAvailableProduct < 0) {
                    return undefined;
                }
                await API.graphql(
                    graphqlOperation(createActiveProduct, {
                        input: { owner: user.getUsername(), activeProductProductId: userProducts[firstIndexOfAvailableProduct].id },
                    }),
                );
                return userProducts[firstIndexOfAvailableProduct];
            } else {
                return undefined;
            }
        } else {
            return getActivateProductResData.product;
        }
    }
    public async getProduct(productId: string): Promise<Product> {
        console.log('getProduct', productId);
        const getProductResult = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(getProduct, { id: productId })),
        );
        const product = (getProductResult as GraphQLResult<any>).data.getProduct;
        return product;
    }
    public async activateUserProduct(user: CognitoUser, productIdToActivate: string) {
        const getActivateProductRes = await API.graphql(graphqlOperation(getActiveProduct, { owner: user.getUsername() }));
        const getActivateProductResData = (getActivateProductRes as GraphQLResult<any>).data.getActiveProduct;
        if (getActivateProductResData == null) {
            await API.graphql(
                graphqlOperation(createActiveProduct, {
                    input: { owner: user.getUsername(), activeProductProductId: productIdToActivate },
                }),
            );
        } else {
            await API.graphql(
                graphqlOperation(updateActiveProduct, {
                    input: { owner: user?.getUsername(), activeProductProductId: productIdToActivate },
                }),
            );
        }
    }

    public async archiveProduct(user: CognitoUser, productId: string): Promise<Product> {
        return this.editProductStatus(user, productId, ProductStatus.ARCHIVED);
    }

    public async createUser(user: CognitoUser): Promise<User> {
        const stage = AmplifyUtils.getAmplifyEnv();
        const attributes = await Auth.userAttributes(user);
        const emailAttribute = attributes.find((attribute) => attribute.getName() === 'email');
        const newStripeCustomerPromise = stripe.customers.create({
            metadata: { client_reference_id: user.getUsername(), stage },
            email: emailAttribute?.getValue(),
        });

        let userLocationPromise: Promise<UserLocation | undefined> = Promise.resolve(undefined);
        if (!EnvironmentUtils.isLocalhost()) {
            userLocationPromise = getUserLocation();
        }

        const [newStripeCustomer, userLocation] = await Promise.all([newStripeCustomerPromise, userLocationPromise]);
        const stripeBillingPortalSessionPromise = stripe.billingPortal.sessions.create({
            customer: newStripeCustomer.id,
        });

        const userOnboardingPromise = API.graphql(
            graphqlOperation(createUser, {
                input: {
                    owner: user.getUsername(),
                    onboarding: {
                        formSteps: [
                            // in case onboarding flow needs to be removed
                            {
                                data: {},
                                id: 0,
                                isSubmited: true,
                            },
                        ],
                        uiSteps: [],
                    },
                    stripeId: newStripeCustomer.id,
                    trialSubscriptionStatus: TrialSubscriptionStatus.NOT_STARTED,
                    limitation: {
                        generatedStoriesInLimitPeriod: 0,
                        generatedReimagines: 0,
                        createdProjectsInLimitPeriod: 0,
                        createdCustomProductsInLimitPeriod: 0,
                        createdStandardProductsInLimitPeriod: 0,
                        downloadedImages: 0,
                        downloadedVideos: 0,
                        generatedPacks: 0,
                        subscriptionStartDate: JSON.stringify(new Date().valueOf()),
                        limitResetDate: JSON.stringify(new Date().valueOf() + 24 * 60 * 60 * 1000 * 7),
                        projectLimit: 1,
                        storiesLimit: 6,
                        storiesLimitPeriod: JSON.stringify(24 * 60 * 60 * 1000 * 7),
                        customProducts: 0,
                        standardProducts: 2,
                        images: 0,
                        videos: 0,
                        packsLimit: 0,
                    },
                    ...(userLocation && {
                        location: {
                            country: userLocation.country,
                            countryName: userLocation.country_name,
                            countryCode: userLocation.country_code,
                            countryCodeISO3: userLocation.country_code_iso3,
                            currency: userLocation.currency,
                            currencyName: userLocation.currency_name,
                            languages: userLocation.languages,
                        },
                    }),
                },
            }),
        );

        const [stripeBillingPortalSession, userOnboardingRes] = await Promise.all([
            stripeBillingPortalSessionPromise,
            userOnboardingPromise,
        ]);

        addCustomAnalyticsUserAttributes(UserAttributesType.SUBSCRIPTION, { 'Plan Type': 'Free' });
        if (userLocation) {
            addCustomAnalyticsUserAttributes(UserAttributesType.USER_DATA, {
                'Country Name': userLocation.country_name,
                Currency: userLocation.currency,
            });
        }
        track(AnalyticsEvents.USER_CREATED_IN_DB);
        const userOnboarding = (userOnboardingRes as GraphQLResult<any>).data.createUser;
        return userOnboarding;
    }

    public async fetchUserById(user: CognitoUser): Promise<User> {
        const getUserResult = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(getUser, { owner: user.getUsername() })),
        );
        const getUserRes = (getUserResult as GraphQLResult<any>).data.getUser;

        return getUserRes;
    }

    public async getOrCreateStripeCustomer(stripeId: string, user: CognitoUser) {
        try {
            const stripeCustomerData = await stripe.customers.retrieve(stripeId, {
                expand: ['subscriptions', 'subscriptions.data.plan.product'],
            });

            return stripeCustomerData;
        } catch (error) {
            const stage = AmplifyUtils.getAmplifyEnv();
            const attributes = await Auth.userAttributes(user);
            const emailAttribute = attributes.find((attribute) => attribute.getName() === 'email');
            const newStripeCustomerData = await stripe.customers.create({
                metadata: { client_reference_id: user.getUsername(), stage },
                email: emailAttribute?.getValue(),
            });

            return newStripeCustomerData;
        }
    }

    public async getOrCreateUserWithLimits(user: CognitoUser): Promise<User> {
        const getUserResult = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(getUser, { owner: user.getUsername() })),
        );
        const getUserRes = (getUserResult as GraphQLResult<any>).data.getUser;
        let stripeCustomerData;
        if (getUserRes == null) {
            const newUser = await this.createUser(user);
            return newUser;
        }
        if (getUserRes.stripeId) {
            stripeCustomerData = await this.getOrCreateStripeCustomer(getUserRes.stripeId, user);
        }
        if (!getUserRes.location && !EnvironmentUtils.isLocalhost()) {
            const userLocation = await getUserLocation();
            if (userLocation) {
                const locationDataForUpdate = {
                    country: userLocation.country,
                    countryName: userLocation.country_name,
                    countryCode: userLocation.country_code,
                    countryCodeISO3: userLocation.country_code_iso3,
                    currency: userLocation.currency,
                    currencyName: userLocation.currency_name,
                    languages: userLocation.languages,
                };
                const updateUserInput: UpdateUserInput = {
                    owner: user.getUsername(),
                    location: locationDataForUpdate,
                };
                await this.updateUserData(updateUserInput);
                getUserRes.location = locationDataForUpdate;
                addCustomAnalyticsUserAttributes(UserAttributesType.USER_DATA, {
                    'Country Name': userLocation.country_name,
                    Currency: userLocation.currency,
                });
            }
        }

        if (!getUserRes.limitation) {
            const data = {
                generatedStoriesInLimitPeriod: 0,
                generatedReimagines: 0,
                createdProjectsInLimitPeriod: 0,
                createdCustomProductsInLimitPeriod: 0,
                createdStandardProductsInLimitPeriod: 0,
                downloadedImages: 0,
                downloadedVideos: 0,
                generatedPacks: 0,
                subscriptionStartDate: JSON.stringify(new Date().valueOf()),
                limitResetDate: JSON.stringify(new Date().valueOf() + 24 * 60 * 60 * 1000 * 7),
                projectLimit: 1,
                storiesLimit: 6,
                storiesLimitPeriod: JSON.stringify(24 * 60 * 60 * 1000 * 7),
                customProducts: 0,
                standardProducts: 2,
                images: 0,
                videos: 0,
                packsLimit: 0,
            };
            const updatedUser = await this.updateUserLimitationData(user, data);
            return updatedUser;
        } else {
            //in case existing user without limitation data for standard products (update user standard product limitation data in DB)
            if (getUserRes.limitation.createdStandardProductsInLimitPeriod === null || getUserRes.limitation.standardProducts === null) {
                let updatedLimitationData = { ...getUserRes.limitation };
                const userProducts = await this.getDisplayableUserProducts(user);
                const user2DProducts = userProducts.filter((product) => product.productType === ProductType.CUSTOM_2D);

                if (
                    stripeCustomerData &&
                    (stripeCustomerData as Stripe.Customer | Stripe.DeletedCustomer | any).subscriptions &&
                    (stripeCustomerData as Stripe.Customer | Stripe.DeletedCustomer | any).subscriptions.data.length
                ) {
                    const stripeCustomerSubscription: Stripe.Subscription | undefined = (
                        stripeCustomerData as Stripe.Customer | Stripe.DeletedCustomer | any
                    ).subscriptions.data[0];
                    const standardProductAmountFromstripeCustomerPlan = (stripeCustomerSubscription as StripeSubscriptionWithPlan).plan
                        .metadata.standardProduct;
                    updatedLimitationData = {
                        ...updatedLimitationData,
                        createdStandardProductsInLimitPeriod: user2DProducts.length,
                        standardProducts:
                            getUserRes.trialSubscriptionStatus && getUserRes.trialSubscriptionStatus === TrialSubscriptionStatus.IN_PROGRESS
                                ? 10
                                : Number(standardProductAmountFromstripeCustomerPlan),
                    };
                } else {
                    updatedLimitationData = {
                        ...updatedLimitationData,
                        createdStandardProductsInLimitPeriod: user2DProducts.length,
                        standardProducts: 2,
                    };
                }
                const updatedUser = await this.updateUserLimitationData(user, updatedLimitationData);

                return updatedUser;
            } else {
                if (getUserRes.limitation.generatedPacks === null || getUserRes.limitation.packsLimit === null) {
                    let updatedLimitationData = { ...getUserRes.limitation };
                    if (
                        stripeCustomerData &&
                        (stripeCustomerData as Stripe.Customer | Stripe.DeletedCustomer | any).subscriptions &&
                        (stripeCustomerData as Stripe.Customer | Stripe.DeletedCustomer | any).subscriptions.data.length
                    ) {
                        const stripeCustomerSubscription: Stripe.Subscription | undefined = (
                            stripeCustomerData as Stripe.Customer | Stripe.DeletedCustomer | any
                        ).subscriptions.data[0];
                        
                        const packsAmountFromStripeCustomerPlan = (stripeCustomerSubscription as StripeSubscriptionWithPlan).plan.product.metadata.packsLimit;

                        updatedLimitationData = {
                            ...updatedLimitationData,
                            generatedPacks: 0,
                            packsLimit:
                                getUserRes.trialSubscriptionStatus && getUserRes.trialSubscriptionStatus === TrialSubscriptionStatus.IN_PROGRESS
                                    ? 1
                                    : Number(packsAmountFromStripeCustomerPlan),
                        };
                    } else {
                        updatedLimitationData = {
                            ...updatedLimitationData,
                            generatedPacks: 0,
                            packsLimit: 0,
                        };
                    }
                    const updatedUser = await this.updateUserLimitationData(user, updatedLimitationData);

                    return updatedUser;
                } else {
                    return getUserRes;
                }
                
            }
        }
    }

    public async getPromptCatalog(): Promise<PromptCatalog | null> {
        const catalogId =
            AmplifyUtils.getAmplifyEnv() == 'production'
                ? DataOperations.PROMPT_CATALOG_PRODUCTION_ID
                : DataOperations.PROMPT_CATALOG_DEV_ID;

        const getPromptCatalogResult = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(getPromptCatalog2LevelElementsSubCategories, { id: catalogId })),
        );
        return (getPromptCatalogResult as GraphQLResult<any>).data.getPromptCatalog;
    }

    public async createPromptHistory(user: CognitoUser, description: string, score: number[]): Promise<PromptHistory> {
        const createPromptHistoryInput: CreatePromptHistoryInput = {
            username: user.getUsername(),
            description,
            score,
        };
        const createPromptHistoryRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(createPromptHistory, { input: createPromptHistoryInput })),
        );
        const createPrompt = (createPromptHistoryRes as GraphQLResult<any>).data.createPromptHistory;

        return createPrompt;
    }

    public async updateUserOnboardingData(user: CognitoUser, data: StepInDB[]): Promise<User> {
        const userOnboarding = await this.getOrCreateUserWithLimits(user);

        if (!userOnboarding) {
            new Error('no such user onboarding data');
        }
        const onboardingData = data[0].data
            ? { formSteps: data, uiSteps: userOnboarding.onboarding?.uiSteps }
            : { uiSteps: data, formSteps: userOnboarding.onboarding?.formSteps };
        const updateUserInput: UpdateUserInput = {
            owner: user.getUsername(),
            onboarding: onboardingData,
        };
        const updateUserRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(updateUser, { input: updateUserInput })),
        );
        const updatedUser = (updateUserRes as GraphQLResult<any>).data.updateUser;
        return updatedUser;
    }
    public async updateUserRolesAndGoalsData(user: CognitoUser, type: string, data: UserAttributeInput[]): Promise<User> {
        const userOnboarding = await this.getOrCreateUserWithLimits(user);
        if (!userOnboarding) {
            new Error('no such user onboarding data');
        }
        const updatedData = type === UserAttributesType.ROLES ? { roles: data } : { goals: data };
        const updateUserInput: UpdateUserInput = {
            owner: user.getUsername(),
            ...updatedData,
        };
        const updateUserRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(updateUser, { input: updateUserInput })),
        );
        const updatedUser = (updateUserRes as GraphQLResult<any>).data.updateUser;
        return updatedUser;
    }
    public async updateUserLimitationData(user: CognitoUser, data: LimitationTypeInput): Promise<User> {
        const updateUserInput: UpdateUserInput = {
            owner: user.getUsername(),
            limitation: data,
        };
        const updateUserRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(updateUser, { input: updateUserInput })),
        );
        const updatedUser = (updateUserRes as GraphQLResult<any>).data.updateUser;
        return updatedUser;
    }

    public async updateUserData(updateUserInput: UpdateUserInput): Promise<User> {
        const updateUserRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(updateUser, { input: updateUserInput })),
        );
        const updatedUser = (updateUserRes as GraphQLResult<any>).data.updateUser;

        return updatedUser;
    }

    public async updateUserSubscriptionCanceledState(user: CognitoUser, data: boolean): Promise<User> {
        const updateUserInput: UpdateUserInput = {
            owner: user.getUsername(),
            isSubscriptionCanceled: data,
        };
        const updateUserRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(updateUser, { input: updateUserInput })),
        );
        const updateUserData = (updateUserRes as GraphQLResult<any>).data.updateUser;
        return updateUserData;
    }

    public async updateUserSeenSceneIds(user: CognitoUser, data: string[]): Promise<User> {
        const updateUserInput: UpdateUserInput = {
            owner: user.getUsername(),
            seenSceneIds: data,
        };
        const updateUserRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(updateUser, { input: updateUserInput })),
        );
        const updatedUser = (updateUserRes as GraphQLResult<any>).data.updateUser;
        return updatedUser;
    }

    public async createDraftProduct(name: string, productType: ProductType): Promise<Product> {
        const user = await Auth.currentAuthenticatedUser();
        const createProductRes = await API.graphql(
            graphqlOperation(createProduct, {
                input: {
                    name: name,
                    status: ProductStatus.DRAFT,
                    path: '',
                    username: user.getUsername(),
                    productType: productType,
                    ...(productType === ProductType.CUSTOM_2D && { supportedViewType: 'FRONT' }),
                },
            }),
        );
        const draftProduct = (createProductRes as GraphQLResult<any>).data.createProduct;
        track(AnalyticsEvents.DRAFT_PRODUCT_CREATED, { productId: draftProduct.id, productName: name, productType });

        return draftProduct;
    }

    public async createProductFromSample(productSample: ProductSample): Promise<Product> {
        const user = await Auth.currentAuthenticatedUser();
        const createProductRes = await API.graphql(
            graphqlOperation(createProduct, {
                input: {
                    name: productSample.name,
                    status: ProductStatus.AVAILABLE,
                    path: productSample.path,
                    productType: ProductType.SAMPLE,
                    thumbnailPath: productSample.thumbnailPath,
                    username: user.getUsername(),
                    ...(productSample.size && { size: productSample.size }),
                },
            }),
        );
        return (createProductRes as GraphQLResult<any>).data.createProduct;
    }
    public async reviewProduct(user: CognitoUser, productId: string): Promise<Product> {
        return this.editProductStatus(user, productId, ProductStatus.IN_REVIEW);
    }

    public async publishProduct(product: Product): Promise<Product | undefined> {
        const updateProductInput: UpdateProductInput = {
            id: product.id,
            status: ProductStatus.AVAILABLE,
            path: product.path,
            thumbnailPath: product.thumbnailPath,
            productType: product.productType,
            name: product.name,
            ...(product.supportedViewType && { supportedViewType: product.supportedViewType }),
            ...(product.productFamily && { productFamily: product.productFamily }),
            ...(product.industryType && { industryType: product.industryType }),
            ...(product.size && { size: product.size }),
        };

        try {
            const updateProductRes = await this.extractResultFromResultOrObservable(
                await API.graphql(graphqlOperation(updateProduct, { input: updateProductInput })),
            );

            return (updateProductRes as GraphQLResult<any>).data.updateProduct;
        } catch (error) {
            console.error('failed to update product', error);
            return undefined;
        }
    }

    private async editProductStatus(user: CognitoUser, productId: string, status: ProductStatus): Promise<Product> {
        const updateProductInput: UpdateProductInput = {
            id: productId,
            status: status,
        };
        const updateProductRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(updateProduct, { input: updateProductInput })),
        );
        const updatedProduct = (updateProductRes as GraphQLResult<any>).data.updateProduct;

        await this.handleProductStatusChange(user, productId);

        return updatedProduct;
    }

    private async handleProductStatusChange(user: CognitoUser, productId: string) {
        const activeProduct = await this.getActiveUserProduct(user);
        if (activeProduct != undefined && activeProduct.id == productId) {
            const deleteActiveProductRes = await this.extractResultFromResultOrObservable(
                await API.graphql(graphqlOperation(deleteActiveProduct, { input: { owner: user.getUsername() } })),
            );
            console.log('deleteActiveProductRes', deleteActiveProductRes);
        }
    }

    public async deleteProject(user: CognitoUser, project: Project) {
        this.imageDataHandler.deleteProjectThumbnailData(project);
        let input = { id: project.id };
        const res = await API.graphql(graphqlOperation(deleteProject, { input }));
        console.log('delete project ' + project.id, res);

        const reimagingeDeletionPromise = this.deleteReimagineRemotePrivateData({ user: user, projectId: project.id });

        track(AnalyticsEvents.PROJECT_DELETED, { projectId: project.id });
    }

    public async deleteProduct(user: CognitoUser, product: Product) {
        let input = { id: product.id };
        const getActivateProductRes = await API.graphql(graphqlOperation(getActiveProduct, { owner: user.getUsername() }));
        const activeProduct = (getActivateProductRes as GraphQLResult<any>).data.getActiveProduct;
        const res = await API.graphql(graphqlOperation(deleteProduct, { input }));
        console.log('delete product ' + product.id, res);
        if (activeProduct != undefined && activeProduct.id == product.id) {
            const deleteActiveProductRes = await this.extractResultFromResultOrObservable(
                await API.graphql(graphqlOperation(deleteActiveProduct, { input: { owner: user.getUsername() } })),
            );
            console.log('deleteActiveProductRes', deleteActiveProductRes);
        }
        track(AnalyticsEvents.PRODUCT_DELETED, { productId: product.id, name: product.name });
    }

    public async addConstructingImagelData(project: Project, imageId: string, thumbnailData: any, contentType: string): Promise<Project> {
        return await this.imageDataHandler.addConstructingImagelData(project, imageId, thumbnailData, contentType);
    }

    public async getProjectThumbnail(project: Project, storyId: string) {
        return this.imageDataHandler.getConstructingImageData(project, storyId);
    }

    public async getProjectThumbnails(project: Project) {
        return this.imageDataHandler.getConstructingImagesData(project);
    }

    public async removeConstructingImagelData(project: Project, imageId: string) {
        return this.imageDataHandler.removeConstructingImagelData(project, imageId);
    }

    public async setProjectThumbnailData(project: Project, thumbnailData: any, contentType: string) {
        return this.imageDataHandler.setProjectThumbnailData(project, thumbnailData, contentType);
    }

    public async createTrackedProgressItem(user: CognitoUser): Promise<TrackedProgressItem> {
        const createTrackedProgressItemRes = await API.graphql(graphqlOperation(createTrackedProgressItem, { input: { progress: 0 } }));
        return (createTrackedProgressItemRes as GraphQLResult<any>).data.createTrackedProgressItem;
    }

    public async updateTrackedProgressItem(id: string, progress: number): Promise<void> {
        const updateTrackedProgressItemInput: UpdateTrackedProgressItemInput = {
            id: id,
            progress: progress,
        };
        const updateTrackedProgressItemRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(updateTrackedProgressItem, { input: updateTrackedProgressItemInput })),
        );
    }

    public async deleteTrackedProgressItem(id: string): Promise<void> {
        const deleteTrackedProgressItemInput: DeleteTrackedProgressItemInput = {
            id: id,
        };
        const deleteTrackedProgressItemRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(deleteTrackedProgressItem, { input: deleteTrackedProgressItemInput })),
        );
    }

    public async createStoryInProject(projectId: string, story: Story): Promise<Story> {
        const storyInput: CreateStoryInput = {
            projectStoriesId: projectId,
            status: story.status,
            selectedValuesOfBlocks: story.selectedValuesOfBlocks,
            optionsPerBlock: story.optionsPerBlock,
            sceneId: story.sceneId,
            sceneBrief: story.sceneBrief,
            sceneName: story.sceneName,
            sceneStatus: story.sceneStatus,
            scenePath: story.scenePath,
            sceneType: story.sceneType,
            format: story.format,
            sceneHasBlurryBG: story.sceneHasBlurryBG,
            reimaginesData: story.reimaginesData,
            similarity: story.similarity,
            isGeneratedFromHistory: story.isGeneratedFromHistory ? story.isGeneratedFromHistory : false,
        };
        return await this.createStory(storyInput);
    }

    private async validateStoryInput(sceneBrief: BriefInput, selectedValuesOfBlocks: (string | null)[] | null | undefined) {
        //verify the number of blocks in the scene is the same as the number of blocks in the story
        const blocksInScene = sceneBrief.blocks;
        const blocksExistInScene = blocksInScene != null;
        const blocksExistInStory = selectedValuesOfBlocks != null;
        if (
            blocksExistInScene != blocksExistInStory ||
            (sceneBrief.blocks != null && selectedValuesOfBlocks != null && sceneBrief.blocks.length != selectedValuesOfBlocks.length)
        ) {
            throw new Error('the number of blocks in the scene is not the same as the number of blocks in the story');
        }
    }

    public async createStory(storyInput: CreateStoryInput): Promise<Story> {
        try {
            await this.validateStoryInput(storyInput.sceneBrief, storyInput.selectedValuesOfBlocks);

            const createStoryrRes = await API.graphql(graphqlOperation(createStory, { input: storyInput }));

            return (createStoryrRes as GraphQLResult<any>).data.createStory;
        } catch (error) {
            console.error('error creating story', error);
            throw error;
        }
    }

    public async getProjectWithId(projectId: string): Promise<Project> {
        const getProjectResult = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(getProject, { id: projectId })),
        );
        const project = (getProjectResult as GraphQLResult<any>).data.getProject;
        return project;
    }

    public async getStoryWithId(storyId: string): Promise<Story> {
        const getStoryResult = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(getStory, { id: storyId })),
        );
        const story = (getStoryResult as GraphQLResult<any>).data.getStory;
        return story;
    }

    private async extractResultFromResultOrObservable(resultOrObservable: GraphQLResult<any> | Observable<object>) {
        return isObservable(resultOrObservable)
            ? await firstValueFrom(resultOrObservable as Observable<GraphQLResult<any>>)
            : resultOrObservable;
    }
    public async deactivateStory(storyId: string): Promise<Story> {
        const updateStoryInput: UpdateStoryInput = {
            id: storyId,
            status: StoryStatus.INACTIVE,
        };
        const updateStorytRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(updateStory, { input: updateStoryInput })),
        );
        const updatedStory = (updateStorytRes as GraphQLResult<any>).data.updateStory;
        return updatedStory;
    }
    public async activateStory(storyId: string): Promise<Story> {
        const updateStoryInput: UpdateStoryInput = {
            id: storyId,
            status: StoryStatus.PUBLISHED,
        };
        const updateStorytRes = await this.extractResultFromResultOrObservable(
            await API.graphql(graphqlOperation(updateStory, { input: updateStoryInput })),
        );
        const updatedStory = (updateStorytRes as GraphQLResult<any>).data.updateStory;
        return updatedStory;
    }
    public async updateStory(story: Story): Promise<Story> {
        try {
            await this.validateStoryInput(story.sceneBrief, story.selectedValuesOfBlocks);
            const updateStoryInput: UpdateStoryInput = {
                id: story.id,
                status: story.status,
                selectedValuesOfBlocks: story.selectedValuesOfBlocks,
                projectStoriesId: story.projectStoriesId,
                optionsPerBlock: story.optionsPerBlock,
                sceneId: story.sceneId,
                sceneBrief: story.sceneBrief,
                sceneName: story.sceneName,
                sceneType: story.sceneType,
                format: story.format,
                sceneStatus: story.sceneStatus,
                sceneHasBlurryBG: story.sceneHasBlurryBG,
                isGeneratedFromHistory: story.isGeneratedFromHistory,
                reimaginesData:
                    story.reimaginesData?.map((reimagine) =>
                        reimagine
                            ? {
                                  s3key: reimagine.s3key,
                                  type: reimagine.type,
                                  selectedValuesOfBlocks: reimagine.selectedValuesOfBlocks,
                                  reimagineInfo: reimagine.reimagineInfo
                                      ? {
                                            category: reimagine.reimagineInfo.category,
                                            option: reimagine.reimagineInfo.option,
                                            preset: reimagine.reimagineInfo.preset,
                                        }
                                      : null,
                              }
                            : null,
                    ) ?? null,
            };

            const updateStorytRes = await this.extractResultFromResultOrObservable(
                await API.graphql(graphqlOperation(updateStory, { input: updateStoryInput })),
            );
            const updatedStory = (updateStorytRes as GraphQLResult<any>).data.updateStory;
            return updatedStory;
        } catch (error) {
            console.error('error updating story', error);
            throw error;
        }
    }
    public async deleteStory(user: CognitoUser, projectId: string, story: Story, deleteReimgaineData: boolean = true) {
        const storyId = story.id;
        const deleteStoryInput: DeleteStoryInput = {
            id: storyId,
        };
        const deletetResPromise = API.graphql(graphqlOperation(deleteStory, { input: deleteStoryInput }));
        if (!deleteReimgaineData) {
            await deletetResPromise;
            return;
        }
        const reimagingeDeletionPromise = this.deleteReimagineRemotePrivateData({ user: user, projectId: projectId, storyId: storyId });
        const deleteCachedReimagineImagesPromise = this.deleteAllReimgineCachedImages(await this.getStoryWithId(storyId));

        await Promise.all([deletetResPromise, reimagingeDeletionPromise, deleteCachedReimagineImagesPromise]);
    }

    private async deleteReimagineRemotePrivateData(props: {
        user: CognitoUser;
        projectId: string;
        storyId?: string;
        imageId?: string;
    }): Promise<void> {
        const idToken = (await Auth.userSession(props.user)).getIdToken();
        try {
            const jwtToken: string = idToken.getJwtToken();
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: jwtToken,
                },
                body: JSON.stringify({
                    projectId: props.projectId,
                    storyId: props.storyId,
                    imageId: props.imageId,
                    Authorization: jwtToken,
                }),
            };
            const response = await API.post(
                AmplifyUtils.getRESTApiName(),
                DataOperations.DELETE_FOLDER_REIMAGINE_PRIVATE_DATA_API_PATH,
                options,
            );
            console.dir(response);
        } catch (error) {
            console.error('error delete reimagine data ', error);
        }
    }

    public async deleteAllInActiveStories(user: CognitoUser, projectId: string) {
        try {
            const project = await this.getProjectWithId(projectId);
            if (!project || project.stories == null) {
                console.log(!project ? 'project is null' : 'project has no stories');

                return;
            }

            for (let story of project.stories.items) {
                if (story == null) {
                    continue;
                }
                if (story.status == StoryStatus.INACTIVE) {
                    this.deleteStory(user, projectId, story);
                }
            }
        } catch (error) {
            console.error('error when trying to delete all inactive stories', error);
        }
    }
    public async deleteAllProjectStories(user: CognitoUser, project: Project) {
        console.log('deleteAllProjectStories', project);
        if (project.stories == null) {
            console.log('project has no stories');
            return;
        }
        const deletePromises: Promise<any>[] = [];
        for (let story of project.stories.items) {
            if (story == null) {
                continue;
            }
            deletePromises.push(this.deleteStory(user, project.id, story, false));
        }

        deletePromises.push(this.deleteReimagineRemotePrivateData({ user: user, projectId: project.id }));

        await Promise.all(deletePromises);
    }

    private async deleteAllReimgineCachedImages(story: Story) {
        if (story.reimaginesData == null) {
            return;
        }
        const deletePromises: Promise<any>[] = [];
        for (let reimagineData of story.reimaginesData) {
            if (reimagineData == null || reimagineData.s3key == null) {
                continue;
            }
            deletePromises.push(this.imageDataHandler.deleteCachedImagePrivateData(reimagineData.s3key));
        }
        await Promise.all(deletePromises);
    }
}
