import { Stripe } from 'stripe';

export enum RedirectUrlType {
    PREV_URL_BEFORE_REDIRECTING_TO_STRIPE = 'prevUrlBeforeRedirectingToStripe',
    ADD_IMMERSIVE_PRODUCT_URL = 'addImmersiveProductUrl',
}

const stripeSecretKey =
    process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY ||
    'sk_test_51MxyDQHo6dIkAPvxueCIZwEMR5Ki2xfH4YsytTgzjK9In3RZxIiycFZIcY2So9nyK3eL56KZ4cM9741weTNdeoQh00w4cQ7zkb';

export const stripeStage = stripeSecretKey.includes('live') ? 'live' : 'test';

export const stripe = new Stripe(stripeSecretKey, {
    apiVersion: '2022-11-15',
});

export enum StripeCheckoutMode {
    SUBSCRIPTION = 'subscription',
    PAYMENT = 'payment'
}

interface StripeLatestInvoiceWithCharge extends Stripe.Invoice {
    charge: Stripe.Charge;
}
interface StripePlanWithMetadataAndProduct extends Stripe.Plan {
    metadata: {
        customProduct: string;
        standardProduct: string;
    };
    product: Stripe.Product;
}

export interface StripeSubscriptionWithPlan extends Stripe.Subscription {
    plan: StripePlanWithMetadataAndProduct;
    latest_invoice: StripeLatestInvoiceWithCharge;
}

export const enterpriseProductData = {
    "id": "",
    "object": "product",
    "active": true,
    "attributes": [],
    "created": 1685015241,
    "default_price": "",
    "description": "Perfect for businesses with different products and needs",
    "features": [
        "Unlimited projects",
        "25 products",
        "300 generations per month",
        "50 video downloads per month",
        "Remove Kartiv watermark"
    ],
    "images": [],
    "livemode": false,
    "metadata": {
        "feature_0": "Unlimited products",
        "feature_1": "Unlimited projects",
        "feature_2": "Custom images and videos",
        "feature_3": "Priority support",
    },
    "name": "Enterprise",
    "package_dimensions": null,
    "shippable": null,
    "statement_descriptor": null,
    "tax_code": "txcd_10000000",
    "type": "service",
    "unit_label": null,
    "updated": 1709732563,
    "url": null,
    "pricesData": [
        {
            "id": "",
            "object": "price",
            "active": true,
            "billing_scheme": "per_unit",
            "created": 1685361818,
            "currency": "usd",
            "custom_unit_amount": null,
            "livemode": false,
            "lookup_key": null,
            "metadata": {
                "feature_0": "Unlimited products",
                "feature_1": "Unlimited projects",
                "feature_2": "Custom images and videos",
                "feature_3": "Priority support",
            },
            "nickname": null,
            "product": "",
            "recurring": {
                "aggregate_usage": null,
                "interval": "year",
                "interval_count": 1,
                "trial_period_days": null,
                "usage_type": "licensed"
            },
            "tax_behavior": "unspecified",
            "tiers_mode": null,
            "transform_quantity": null,
            "type": "recurring",
            "unit_amount": 0,
            "unit_amount_decimal": "0"
        },
        {
            "id": "",
            "object": "price",
            "active": true,
            "billing_scheme": "per_unit",
            "created": 1685015241,
            "currency": "usd",
            "custom_unit_amount": null,
            "livemode": false,
            "lookup_key": null,
            "metadata": {
                "feature_0": "Unlimited products",
                "feature_1": "Unlimited projects",
                "feature_2": "Custom images and videos",
                "feature_3": "Priority support",
            },
            "nickname": null,
            "product": "",
            "recurring": {
                "aggregate_usage": null,
                "interval": "month",
                "interval_count": 1,
                "trial_period_days": null,
                "usage_type": "licensed"
            },
            "tax_behavior": "unspecified",
            "tiers_mode": null,
            "transform_quantity": null,
            "type": "recurring",
            "unit_amount": 0,
            "unit_amount_decimal": "0"
        }
    ],
    "priceMonthly": {
        "priceId": "",
        "price": 0,
        "customProduct": 0,
        "features": [
            "Unlimited products",
            "Unlimited projects",
            "Custom images and videos",
            "Priority support",
        ]
    },
    "priceYearly": {
        "priceId": "",
        "price": 0,
        "customProduct": 0,
        "features": [
            "Unlimited products",
            "Unlimited projects",
            "Custom images and videos",
            "Priority support",
        ]
    }
};

export const defaultPriceCurrency = 'usd';
