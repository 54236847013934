export default class S3UrlReplacement {
    private static readonly S3_PUB_BUCKET_PREFIX_PATH = 'https://kartivpub';
    private static readonly S3_PUB_BUCKET_SUFFIX_PATH = 'amazonaws.com/';
    private static readonly S3_PUB_BUCKET_PATH = 'https://kartivpub.s3.us-west-1.amazonaws.com/';
    public static readonly CLOUD_FRONT_TO_S3_PUB_BUCKET_PATH = 'https://d144hvnbhdbhf0.cloudfront.net/';

    static replaceS3UrlWithCloudfrontUrl(initialUrl: string): string {
        const targetPrefix = S3UrlReplacement.S3_PUB_BUCKET_PATH;
        const replaceWith = S3UrlReplacement.CLOUD_FRONT_TO_S3_PUB_BUCKET_PATH;
        if (initialUrl.startsWith(targetPrefix)) {
            const newUrl = initialUrl.replace(targetPrefix, replaceWith);
            return newUrl;
        }
        if (initialUrl.startsWith(S3UrlReplacement.S3_PUB_BUCKET_PREFIX_PATH)) {
            //look for S3_PUB_BUCKET_SUFFIX_PATH in initialUrl if it is found replace all until the suffix with replaceWith
            const suffixIndex = initialUrl.indexOf(S3UrlReplacement.S3_PUB_BUCKET_SUFFIX_PATH);
            if (suffixIndex > 0) {
                const newUrl = replaceWith + initialUrl.substring(suffixIndex + S3UrlReplacement.S3_PUB_BUCKET_SUFFIX_PATH.length);
                return newUrl;
            }
        }

        return initialUrl;
    }
}
